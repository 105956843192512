import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CreateIcon from '@mui/icons-material/CreateOutlined'
import * as repoutil from './util/repoutil'

export interface AppTitleBarProps {
  leftCornerNode?: React.ReactNode
  rightCornerNode?: React.ReactNode
  title?: string
  titleCenter?: boolean
  hideLogo?: boolean
}

export default function AppTitleBar({
  leftCornerNode,
  rightCornerNode,
  title,
  titleCenter,
  hideLogo,
}: AppTitleBarProps) {
  const _title = title === undefined ? 'Music Berry' : title
  const _titleCenter = titleCenter === undefined ? false : titleCenter
  const _hideLogo = hideLogo === undefined ? false : hideLogo

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 0,
        marginTop: '0px !important',
      }}
    >
      <Stack>
        <Toolbar
          sx={{
            minHeight: '50px !important',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <>
            {leftCornerNode ? leftCornerNode : null}
            {_hideLogo ? (
              ''
            ) : (
              <img
                src="/logo192_transparent.png"
                width="24px"
                style={{ marginRight: '8px' }}
              />
            )}
            <Typography
              fontSize={'20px'}
              sx={
                _titleCenter
                  ? { flexGrow: 1, textAlign: 'center', color: '#000000' }
                  : { flexGrow: 1, color: '#000000' }
              }
            >
              {/* {hideLogo ? '' : '🎸 '} */}

              <b>{_title}</b>
            </Typography>
            {rightCornerNode ? rightCornerNode : null}
          </>
        </Toolbar>

        <Divider orientation="horizontal" flexItem sx={{ color: '#F9F9F9' }} />
      </Stack>
    </AppBar>
  )
}
