import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
} from '@mui/icons-material'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { GRAY, GRAY_DARK } from '../theme/theme'

export default function SettingsCategoryHeader({ value, subHeader }) {
  return (
    <>
      <span
        style={{
          fontSize: '14px',
          color: GRAY,
          fontWeight: 'bold',
          paddingLeft: '16px',
          paddingBottom: '12px',
        }}
      >
        {value}
      </span>
      {subHeader && (
        <span
          style={{
            fontSize: '12px',
            color: GRAY,
            paddingBottom: '12px',
          }}
        >
          {` | ${subHeader}`}
        </span>
      )}
    </>
  )
}
