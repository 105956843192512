import React, { useState, useEffect, useRef, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  Modal,
  SwipeableDrawer,
  Alert,
  ButtonProps,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Visibility,
  VisibilityOff,
  AccessTime,
  PlayArrow,
  Pause,
} from '@mui/icons-material'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as timeutil from '../util/timeutil'
import * as expressionutil from '../util/expressionutil'
import { useLottie } from 'lottie-react'
import { GRAY_LIGHT, GREEN } from '../theme/theme'

export const OutlinedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  // fontSize: 16,
  // padding: '6px 12px',
  // border: '1px solid',
  // borderRadius: '10px',
  color: GREEN,
  // width: '100%',
  // lineHeight: 1.5,
  backgroundColor: '#FFFFFF',
  borderColor: GREEN,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: GREEN,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: GREEN,
    borderColor: GREEN,
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(220,220,220,.5)',
  },
})

export const RedOutlinedButtonSmall = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  borderRadius: '10px',
  color: '#FF9494',
  width: '100%',
  lineHeight: 1.5,
  backgroundColor: '#FFFFFF',
  borderColor: '#FF9494',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: '#FF9494',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: '#FF9494',
    borderColor: '#FF9494',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(220,220,220,.5)',
  },
})

export const GrayOutlinedButtonSmall = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  borderRadius: '10px',
  color: GRAY_LIGHT,
  width: '100%',
  lineHeight: 1.5,
  backgroundColor: '#FFFFFF',
  borderColor: GRAY_LIGHT,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: GRAY_LIGHT,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: GRAY_LIGHT,
    borderColor: GRAY_LIGHT,
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(220,220,220,.5)',
  },
})

export const GradientButton = styled(Button)<ButtonProps>(({ theme }) => ({
  background:
    'linear-gradient(315deg, #ABD453 0%, #91D73E 30%, #91D73E 70%, #46E460 100%);',
  color: '#FFFFFF',
  textTransform: 'none',
  transition: theme.transitions.create(['background', 'transform'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    background:
      'linear-gradient(315deg, #ABD453 0%, #91D73E 30%, #91D73E 70%, #46E460 100%);',
    transform: 'scale(1.05)',
  },
  '&:active': {
    background:
      'linear-gradient(315deg, #ABD453 0%, #91D73E 30%, #91D73E 70%, #46E460 100%);',
    transform: 'scale(0.95)',
  },
  '&:disabled': {
    background:
      'linear-gradient(315deg, #ABD453 0%, #91D73E 30%, #91D73E 70%, #46E460 100%);',
    color: theme.palette.text.disabled,
  },
}))
