import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
} from 'react'
import { styled, SxProps, Theme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Alert,
  Card,
  Chip,
  StackOwnProps,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Visibility,
  VisibilityOff,
  AccessTime,
  PlayArrow,
  Pause,
  Close,
  PushPin,
} from '@mui/icons-material'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import TaskView from './TaskView/TaskView'
import TaskViewReadonly from './TaskView/TaskViewReadonly'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'
import * as expressionutil from './util/expressionutil'
import {
  currentPracticeDayState,
  retrievePracticeDay,
} from './state/practiceday'
import {
  GRADIENT_LIGHT_GREEN,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_LIGHTEST,
} from './theme/theme'
import { OutlinedButton } from './Components/Button'
import PracticeSongView from './Practice/PracticeSongView'

import { PracticeTaskOutputDto } from './types/practiceTask'
import PracticeTaskGroupHeader from './Practice/SongPracticeTaskGroupHeader'
import { PracticeDayPracticeTaskGroupDto } from './types/practiceDay'
import SongPracticeTaskGroupHeader from './Practice/SongPracticeTaskGroupHeader'
import NonSongPracticeTaskGroupHeader from './Practice/NonSongPracticeTaskGroupHeader'
import { PracticeNoteOutputDto } from './types/practiceNote'
import { on } from 'events'
import { YYYYMMDDDateString } from './types/common'
import { BoxOwnProps, BoxTypeMap } from '@mui/system'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export enum PracticeNoteListQueryType {
  ALL,
  PINNED_ONLY_EXCLUDING_DATE,
  PINNED_ONLY,
  DATE_ONLY,
}

export interface PracticeNoteListViewProps {
  date: YYYYMMDDDateString
  props?: StackOwnProps
  itemProps?: BoxOwnProps
  queryType?: PracticeNoteListQueryType
  fallback?: JSX.Element
}

// date props는 new Date()와 동일한 타입
export default function PracticeNoteListView({
  date,
  props,
  itemProps,
  queryType = PracticeNoteListQueryType.ALL,
  fallback,
}: PracticeNoteListViewProps) {
  const navigate = useNavigate()
  const [notes, setNotes] = useState<PracticeNoteOutputDto[]>([])

  useEffect(() => {
    refreshPinnedPracticeNotes()
  }, [date])

  const retreivePracticeNotesForDate = async () => {
    const resp = await repoutil.http(`practiceNotes?date=${date}`)
    const data = await resp.json()
    return data.data
  }

  const retreivePinnedPracticeNotes = async () => {
    const resp = await repoutil.http(`practiceNotes?isPinned=true`)
    const data = await resp.json()
    return data.data
  }

  const refreshPinnedPracticeNotes = async () => {
    const pinnedNotes = await retreivePinnedPracticeNotes()
    const notesForDate = await retreivePracticeNotesForDate()

    setNotes(filterNotes(pinnedNotes, notesForDate))
  }

  const onClickPin = async (practiceNote: PracticeNoteOutputDto) => {
    const resp = await repoutil.post(
      `practiceNotes/${practiceNote.id}/updatePinStatus`,
      {
        isPinned: !practiceNote.pinnedAt,
      },
    )
    const data = await resp.json()
    refreshPinnedPracticeNotes()
  }

  const navigateToEditPostPage = (practiceNote: PracticeNoteOutputDto) => {
    navigate(`/posts/edit`, {
      state: {
        practiceNote: practiceNote,
      },
    })
  }

  const filterNotes = (
    pinnedNotes: PracticeNoteOutputDto[],
    notesForDate: PracticeNoteOutputDto[],
  ): PracticeNoteOutputDto[] => {
    switch (queryType) {
      case PracticeNoteListQueryType.ALL:
        return ([] as PracticeNoteOutputDto[]).concat(
          pinnedNotes,
          notesForDate.filter(
            (practiceNote) =>
              !pinnedNotes.map((pinned) => pinned.id).includes(practiceNote.id),
          ),
        )
      case PracticeNoteListQueryType.PINNED_ONLY:
        return pinnedNotes
      case PracticeNoteListQueryType.PINNED_ONLY_EXCLUDING_DATE:
        return pinnedNotes.filter(
          (practiceNote) =>
            !notesForDate.map((note) => note.id).includes(practiceNote.id),
        )
      case PracticeNoteListQueryType.DATE_ONLY:
        return notesForDate
    }
  }

  return (
    <>
      {notes.length > 0 ? (
        <Stack direction="column" spacing={1} {...props}>
          {notes.map((practiceNote) => (
            <Box
              sx={{
                ...{ backgroundColor: '#FFFFFF' },
                ...itemProps?.sx,
              }}
              borderRadius={2}
              p={1.5}
              display={'flex'}
              {...itemProps}
            >
              <Typography
                onClick={(e) => navigateToEditPostPage(practiceNote)}
                color={GRAY_DARKEST}
                sx={{ whiteSpace: 'pre-wrap' }}
                flexGrow={1}
              >
                {practiceNote.content}
              </Typography>
              <PushPin
                style={{
                  color: practiceNote.pinnedAt ? GRAY_DARK : GRAY_LIGHT,
                  width: '18px',
                }}
                onClick={(e) => {
                  onClickPin(practiceNote)
                }}
              />
            </Box>
          ))}
        </Stack>
      ) : (
        fallback
      )}
    </>
  )
}
