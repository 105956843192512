import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
  TextField,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'
import {
  currentPracticeDayState,
  retrievePracticeDay,
} from './state/practiceday'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import { YYYYMMDDDateString } from './types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from './types/practiceTask'
import { format, subDays } from 'date-fns'
import { todayPracticeDayState } from './state/practiceday'
import { GradientButton, OutlinedButton } from './Components/Button'
import { z } from 'zod'
import BasePage from './BasePage'

// Define props for this page
export interface WritePostPageProps {}

const CreatePracticeNoteFormData = z.object({
  content: z.string({
    message: 'Please input content',
  }),
})

export type CreatePracticeNoteFormData = z.infer<
  typeof CreatePracticeNoteFormData
>

export default function WritePostPage({}: WritePostPageProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [date] = useState<YYYYMMDDDateString>(
    searchParams.get('date') || '0000-00-00',
  )
  const [, setPracticeDay] = useRecoilState(currentPracticeDayState)
  const navigate = useNavigate()
  const contentRef = useRef<HTMLInputElement>(null)
  const topNavRef = useRef<HTMLDivElement>(null)
  const bottomNavRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [topNavHeight, setTopNavHeight] = useState<number>(0)
  const [bottomNavHeight, setBottomNavHeight] = useState<number>(0)
  const submitButtonRef = useRef<HTMLInputElement | null>(null)
  const emojis = ['🎯', '🔥', '🚀', '🎉', '😃', '🥲', '👍', '👎']
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
  } = useForm<CreatePracticeNoteFormData>({ defaultValues: { content: '' } })

  useEffect(() => {
    const calculateContentHeight = () => {
      const topNavHeight = topNavRef.current?.offsetHeight || 0
      const bottomNavHeight = bottomNavRef.current?.offsetHeight || 0
      const availableHeight =
        window.innerHeight - topNavHeight - bottomNavHeight

      setContentHeight(availableHeight)
      setTopNavHeight(topNavHeight)
      setBottomNavHeight(bottomNavHeight)
    }

    calculateContentHeight() // Initial calculation

    // Recalculate when the window is resized
    window.addEventListener('resize', calculateContentHeight)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [])

  const onSubmit = async (formData: CreatePracticeNoteFormData) => {
    const handleSavePractice = async () => {
      const resp = await repoutil.post(`practiceNotes`, {
        ...formData,
        date: date,
      })
      const data = await resp.json()
      console.log(data)
    }
    await handleSavePractice()
    await refreshTodayPracticeDay()

    navigate(-1)
  }
  const generateEmojiClickHandler = (emoji: string) => {
    return (e: SyntheticEvent) => {
      const inputElement = contentRef.current
      const start = inputElement?.selectionStart || 0
      const end = inputElement?.selectionEnd || 0
      const inputValue = inputElement?.value || ''
      const newValue = `${inputValue.slice(0, start)}${emoji}${inputValue.slice(
        end,
      )}`
      setValue('content', newValue)
      inputElement?.focus()
      // @ts-ignore
      inputElement.selectionStart = start + emoji.length
      // @ts-ignore
      inputElement.selectionEnd = start + emoji.length

      e.preventDefault()
    }
  }

  const refreshTodayPracticeDay = async () => {
    const resp = await repoutil.http(`practiceDays/${date}`)
    const data = await resp.json()
    await setPracticeDay(data.data)
  }

  const onClickBackButton = () => {
    navigate(-1)
  }

  return (
    <BasePage
      appTitleBarProps={{
        leftCornerNode: (
          <IconButton
            edge="start"
            aria-label="back"
            onClick={onClickBackButton}
          >
            <ArrowBackIosOutlined sx={{ color: GRAY }} />
          </IconButton>
        ),
        rightCornerNode: (
          <Typography
            color={GRAY_DARK}
            onClick={(e) => submitButtonRef.current?.click()}
          >
            Save
          </Typography>
        ),
        title: 'Write',
        titleCenter: true,
        hideLogo: true,
      }}
      child={
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <input hidden={true} type="submit" ref={submitButtonRef} />

          <Grid
            container
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
          >
            <Grid xs={12}>
              <Stack pt={1} direction={'row'} justifyContent={'space-evenly'}>
                {emojis.map((emoji) => (
                  <Typography
                    fontSize="24px"
                    onClick={generateEmojiClickHandler(emoji)}
                  >
                    {emoji}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    minRows={4}
                    fullWidth
                    inputRef={contentRef}
                    sx={{
                      backgroundColor: 'white',
                      '& fieldset': { border: 'none' },
                    }}
                    variant="outlined"
                    placeholder="Enter your note here"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      }
    />
  )
}
