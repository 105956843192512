import React, {
  Fragment,
  useState,
  useRef,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Stack,
  Container,
  Paper,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Input from '@mui/material/Input'
import VolumeUp from '@mui/icons-material/VolumeUp'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  CameraAlt,
  Menu,
  Check,
  Delete,
  DragHandle,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { useLottie } from 'lottie-react'
import { BLACK, GRAY, GRAY_DARK, GRAY_DARKEST } from '../theme/theme'
import { YYYYMMDDDateString } from '../types/common'
import { PracticeTaskOutputDto } from '../types/practiceTask'

const MEDIA_TYPE_VIDEO = 'video'
const MEDIA_TYPE_IMAGE = 'image'

const TASK_TEXT_COLOR = '#3A3A3A'
const ROUTINE_TEXT_COLOR = '#999999'

export type onClickDeleteTask = (id: number) => void
export type onTaskDone = (e: any, task: PracticeTaskOutputDto) => void

export interface TaskViewProps {
  _task: PracticeTaskOutputDto
  // handleRepetitionChange: (e: any, value: number) => void
  onClickDeleteTask: onClickDeleteTask
  onTaskDone: onTaskDone
  // onClickCreateTaskFromRoutine: (task: PracticeTaskOutputDto) => void
  // onClickDeleteRoutine: (id: number) => void
}
export default function TaskView({
  _task,
  onTaskDone,
  onClickDeleteTask,
}: TaskViewProps) {
  // isTask: true인 경우 _task가 곧 정보이고,
  // !isTask인 경우 task는 원본 routine이며 taskToCreate는 routine을 바탕으로 생성할 task data이다.
  const [task, setTask] = useState<PracticeTaskOutputDto>(_task)
  const [isModified, setIsModified] = useState(false)

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true)
  }
  // Increment repetition count
  const onClickTask = async (e: MouseEvent<HTMLElement>) => {
    let cnt = task.todo.isDone ? 1 : 0
    if (0 < cnt) {
      cnt = 0
    } else {
      onTaskDone(e, task)
      cnt = 1
    }
    const newTask = {
      ...task,
      todo: {
        ...task.todo,
        isDone: !task.todo.isDone,
      },
    } as PracticeTaskOutputDto
    setTask(newTask)

    const resp = await repoutil.put(`practiceTasks/${task.id}`, {
      title: newTask.title,
      practiceSongId: newTask.practiceSong.id,
      todo: newTask.todo,
    })
    const data = resp.json()
  }

  const _onClickDeleteTask = async (e: MouseEvent<SVGGElement>) => {
    e.preventDefault()
    await onClickDeleteTask(task.id)
  }

  return (
    <Stack
      spacing={1.3}
      paddingTop={1}
      paddingLeft={1.5}
      paddingRight={1.5}
      paddingBottom={1}
      sx={{
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box m={'2px'} onClick={onClickTask}>
        <img
          src={
            task.todo.isDone
              ? '/strawberry_icon.png'
              : '/strawberry_unchecked_icon.png'
          }
          width={24}
        />
      </Box>
      <Box flexGrow={1} onClick={onClickTask}>
        <Typography
          fontSize={'16px'}
          fontWeight={'bold'}
          fontStyle={{
            color: task.todo.isDone ? GRAY : GRAY_DARKEST,
            textDecoration: task.todo.isDone ? 'line-through' : 'none',
          }}
        >
          {task.title}
        </Typography>
      </Box>
      <Box alignSelf={'center'}>
        <Stack spacing={0.5}>
          <Delete
            sx={{
              color: '#D9D9D9',
            }}
            onClick={_onClickDeleteTask}
          />
        </Stack>
      </Box>
    </Stack>
  )
}
