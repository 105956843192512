import React, { Fragment, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  Container,
  Button,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'

import * as dateutil from './util/dateutil'
import CalendarWeeklyView from './CalendarWeeklyView'
import PracticeDayView from './PracticeDayView/PracticeDayView'

export interface CalendarViewProps {
  dates: Date[]
}
export default function CalendarView({ dates }: CalendarViewProps) {
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

  return (
    <Stack
      sx={{
        marginBottom: '12px !important',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
      p={2}
      direction={'column'}
      spacing={0.66}
    >
      <Stack
        direction={'row'}
        spacing={1.5}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {days.map((day) => (
          <Box
            key={day}
            sx={{
              width: '28px',
            }}
            fontSize={'11px'}
            textAlign={'center'}
            color={'#333333'}
          >
            {day}
          </Box>
        ))}
      </Stack>

      <CalendarWeeklyView dates={dates.slice(0, 7)} />
      <CalendarWeeklyView dates={dates.slice(7, 14)} />
      <CalendarWeeklyView dates={dates.slice(14, 21)} />
      <CalendarWeeklyView dates={dates.slice(21, 28)} />
    </Stack>
  )
}
