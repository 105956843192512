import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  ButtonGroup,
  Drawer,
  TextField,
  SwipeableDrawer,
  Fab,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
} from '@mui/icons-material'
import CustomToggleButtonGroup from './CustomToggleButtonGroup'
import PlayingJournalView from './PlayingJournalView/PlayingJournalView'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as userutil from './util/userutil'
import { getHomeUrl } from './config/config'

export default function UpdateProfilePage() {
  const currentUsername = repoutil.getUserInfo()['username']
  const [userInfo, setUserInfo] = useState({
    username: currentUsername,
    nickname: '',
    description: '',
    musicActivityType: 'GUITAR',
  })
  const navigate = useNavigate()

  const generateInputChangeHandler = (key) => {
    return (event) =>
      setUserInfo({
        ...userInfo,
        [key]: event.target.value,
      })
  }

  const onClickBack = () => {
    navigate(-1)
  }
  useEffect(() => {
    userutil.fetchUserInfo().then((data) => setUserInfo(data))
  }, [])

  const onSubmit = async () => {
    const resp = await repoutil.put(`users/${currentUsername}`, userInfo)
    const data = await resp.json()
    console.log(data)
    window.location.replace(getHomeUrl())
  }
  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: 0,
        }}
      >
        <Toolbar>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <ArrowBackIosOutlined onClick={onClickBack} />
          </IconButton>
          <Box
            justify="center"
            alignItems="center"
            style={{ flexGrow: 1, marginRight: '10px', marginLeft: '10px' }}
          >
            <Typography fontSize={16} textAlign={'center'}>
              Update your profile
            </Typography>
          </Box>

          {/* Icon on the right */}
          <IconButton edge="end" onClick={onSubmit}>
            <Create />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container>
        <Stack spacing={2} direction={'column'}>
          <TextField
            id="username"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
              },
            }}
            disabled
            value={userInfo['username']}
            onChange={generateInputChangeHandler('username')}
          />
          <TextField
            label="Profile message"
            id="profile-message"
            multiline
            rows={3}
            variant="filled"
            inputProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={userInfo['description']}
            onChange={generateInputChangeHandler('description')}
          />
          <Typography fontSize="16px" marginTop={2}>
            Music activity type
          </Typography>
          <Typography fontSize="12px" marginTop={2} color="#D9D9D9">
            <i>Currently, guitar is the only option supported.</i>
          </Typography>
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical outlined button group"
            value={userInfo['musicActivityType']}
          >
            <Button>🎸 Guitar</Button>
            <Button disabled>🎹 Piano </Button>
            <Button disabled>🎤 Vocal </Button>
            <Button disabled>🥁 Drums </Button>
          </ButtonGroup>
        </Stack>
      </Container>
    </Box>
  )
}
