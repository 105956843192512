import React, { Fragment, useState, useEffect } from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  Link,
  TextField,
  Container,
  Button,
  FormControlLabel,
  FormControl,
  FormGroup,
  InputLabel,
  Checkbox,
  MenuItem,
  Image,
  Select,
  ButtonGroup,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  CheckBox,
} from '@mui/icons-material'
import AppleLogin from 'react-apple-login'

import * as userutil from '../util/userutil'
import * as repoutil from '../util/repoutil'
import { GRAY, GRAY_DARK } from '../theme/theme'
import AppTitleBar from '../AppTitleBar'
import { GradientButton } from '../Components/Button'

const TERMS_OF_SERVICVE_URL = process.env.REACT_APP_TERMS_OF_SERVICVE_URL
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL

const consentBoxStackProps = {
  direction: 'row',
  display: 'flex-start',
  alignItems: 'center',
  width: '100%',
  color: 'gray',
}
const consentAnchorProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}
export default function SignUpPage() {
  const location = useLocation()
  const qs = location.search
  const params = new URLSearchParams(qs)
  const appleToken = params.get('appleToken') ? params.get('appleToken') : ''
  const isAppleUser = appleToken != null && appleToken.length > 0

  const languages = [
    ['ENGLISH', 'English'],
    ['KOREAN', '한글'],
    ['CHINESE', '中文'],
    ['JAPANESE', '日本語'],
    ['SPANISH', 'Español'],
    ['FRENCH', 'Français'],
    ['ITALIANO', 'Italiano'],
    ['PORTUGUESE', 'Português'],
  ]
  const [inputData, setInputData] = useState({
    username: '',
    password: '',
    language: 'ENGLISH',
    appleToken: appleToken,
    consentToServiceTerms: false,
    consentToPrivacyPolicy: false,
    instruments: [],
    genres: [],
  })
  const navigate = useNavigate()
  const generateChangeHandler = (key) => {
    return (event) => {
      inputData[key] = event.target.value
      setInputData({ ...inputData })
    }
  }
  const onChangeLanguage = (event, newValue) => {
    setInputData({ ...inputData, language: event.target.value })
  }
  const generateCheckboxChangeHandler = (key) => {
    return (event) => {
      inputData[key] = event.target.checked
      setInputData({ ...inputData })
    }
  }
  const onSubmit = async () => {
    const resp = await repoutil.post('users', inputData)
    const data = await resp.json()

    navigate('/signin', {
      state: { username: inputData.username, password: inputData.password },
    })
  }

  const onClickBackButton = () => {
    navigate(-1)
  }

  const disabled = () =>
    inputData['username'].length == 0 ||
    inputData['consentToPrivacyPolicy'] == false ||
    inputData['consentToServiceTerms'] == false ||
    (!isAppleUser && inputData['password'].length == 0)

  return (
    <Box>
      <AppTitleBar
        leftCornerNode={
          <IconButton
            edge="start"
            aria-label="back"
            onClick={onClickBackButton}
          >
            <ArrowBackIosOutlined sx={{ color: GRAY }} />
          </IconButton>
        }
        title={'Sign Up'}
        titleCenter={true}
        hideLogo={true}
      />
      <Container>
        <Stack
          direction={'column'}
          spacing={1.5}
          justifyContent={'center'}
          alignItems={'center'}
          m={2}
        >
          <TextField
            id="username"
            label="Username"
            fullWidth
            inputProps={{
              style: {
                fontSize: '12px',
              },
            }}
            onChange={generateChangeHandler('username')}
          />
          {isAppleUser ? null : (
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              inputProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              onChange={generateChangeHandler('password')}
            />
          )}
          <FormControl fullWidth>
            <InputLabel id="preferredLanguage">Preferred language</InputLabel>
            <Select
              labelId="preferredLanguage"
              id="language"
              value={inputData.language}
              aria-label="Preferred languange"
              label="Preferred language"
              fullWidth={true}
              onChange={onChangeLanguage}
            >
              {languages.map((language, i) => (
                <MenuItem key={language[0]} value={language[0]}>
                  {language[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack {...consentBoxStackProps}>
            <Checkbox
              onChange={generateCheckboxChangeHandler('consentToServiceTerms')}
            />
            <Typography color={GRAY_DARK}>
              (Required) Agree terms of service (
              <a {...consentAnchorProps} href={TERMS_OF_SERVICVE_URL}>
                link
              </a>
              )
            </Typography>
          </Stack>
          <Stack {...consentBoxStackProps}>
            <Checkbox
              onChange={generateCheckboxChangeHandler('consentToPrivacyPolicy')}
            />
            <Typography color={GRAY_DARK}>
              (Required) Agree to personal information collection and terms of
              use(
              <a {...consentAnchorProps} href={PRIVACY_POLICY_URL}>
                link
              </a>
              )
            </Typography>
          </Stack>
          {disabled() ? (
            <Button
              variant="contained"
              disableElevation
              fullWidth
              disabled={
                inputData['username'].length == 0 ||
                inputData['consentToPrivacyPolicy'] == false ||
                inputData['consentToServiceTerms'] == false ||
                (!isAppleUser && inputData['password'].length == 0)
              }
              style={{ textTransform: 'none' }}
            >
              Sign up
            </Button>
          ) : (
            <GradientButton disableElevation fullWidth onClick={onSubmit}>
              Sign up
            </GradientButton>
          )}
        </Stack>
      </Container>
    </Box>
  )
}
