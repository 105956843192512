import React, { Fragment, useState, useEffect, useRef, ReactNode } from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  TextField,
  Container,
  Button,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
// import AppleLogin from 'react-apple-login'
import AppleLogin from './AppleSignInButton'
import { createTheme } from '@mui/material/styles'
import * as repoutil from '../util/repoutil'
import { GradientButton, OutlinedButton } from '../Components/Button'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'
import { getHomeUrl } from '../config/config'

const SignInFormDataSchema = z.object({
  username: z.string(),
  password: z.string(),
})
export type SignInFormData = z.infer<typeof SignInFormDataSchema>

export default function SignInPage() {
  const location = useLocation()
  const onClickSignUp = () => {
    navigate('/signup')
  }
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm<SignInFormData>({
    defaultValues: {
      username: undefined,
      password: undefined,
    },
    resolver: zodResolver(SignInFormDataSchema),
  })

  const locationState = location.state as
    | { username: string; password: string }
    | undefined
  if (locationState) {
    const { username, password } = locationState
    setValue('username', username)
    setValue('password', password)
  }

  const navigate = useNavigate()

  console.log(isDirty)

  const onSubmit = async (formData: SignInFormData) => {
    const resp = await repoutil.post('auth/issue', formData)
    const data = await resp.json()
    if (!resp.ok) {
      alert(data['error'])
      return
    }
    console.log(data)
    const accessToken = data['data']['accessToken']
    repoutil.saveAuthentication(accessToken)
    window.location.replace(getHomeUrl())
  }
  const passwordRef = useRef<HTMLDivElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const handleUsernameKeyDown = (event: React.KeyboardEvent) => {
    console.log(event.key)
    if (event.key === 'Enter') {
      event.preventDefault()
      // @ts-ignore
      console.log(passwordRef.current)
      // @ts-ignore
      passwordRef.current.focus()
    }
  }
  const handlePasswordKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      // @ts-ignore
      submitButtonRef.current.click()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxWidth={'xs'}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Stack
          direction={'column'}
          spacing={1.5}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={3}
          width={'80%'}
        >
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            columnGap={2}
          >
            <Grid item xs={3}>
              <img src="/logo512_transparent.png" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize="24px">
                <b>Music</b>
              </Typography>
              <Typography fontSize="24px">
                <b>Berry</b>
              </Typography>
            </Grid>
          </Grid>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="username"
                label="Username"
                fullWidth
                onKeyDown={handleUsernameKeyDown}
                inputProps={{
                  style: {
                    fontSize: '12px',
                  },
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="password"
                label="Password"
                type="password"
                fullWidth
                onKeyDown={handlePasswordKeyDown}
                inputProps={{
                  ref: passwordRef,
                  style: {
                    fontSize: '12px',
                  },
                }}
              />
            )}
          />

          {isDirty ? (
            <>
              <GradientButton
                ref={submitButtonRef}
                fullWidth
                disableElevation={true}
                type="submit"
                sx={{ fontSize: '18px', fontWeight: 'bold' }}
              >
                Sign in
              </GradientButton>
              <OutlinedButton
                fullWidth
                disableElevation={true}
                onClick={onClickSignUp}
                variant="outlined"
                sx={{ fontSize: '18px', fontWeight: 'bold' }}
              >
                Sign up
              </OutlinedButton>
            </>
          ) : (
            <>
              <OutlinedButton
                ref={submitButtonRef}
                fullWidth
                disableElevation={true}
                type="submit"
                variant="outlined"
                sx={{ fontSize: '18px', fontWeight: 'bold' }}
              >
                Sign in
              </OutlinedButton>
              <GradientButton
                fullWidth
                disableElevation={true}
                onClick={onClickSignUp}
                sx={{ fontSize: '18px', fontWeight: 'bold' }}
              >
                Sign up
              </GradientButton>
            </>
          )}
          <AppleLogin />
        </Stack>
        {process.env.NODE_ENV === 'development' && (
          <DevTool control={control} placement="top-right" />
        )}
      </Container>
    </form>
  )
}
