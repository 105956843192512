import * as repoutil from './repoutil'
export function getRandomNickname(language = 'en') {
  const adjs = {
    en: [
      'Mystical',
      'Joyful',
      'Breezy',
      'Sassy',
      'Radiant',
      'Enchanting',
      'Soothing',
      'Captivating',
      'Cheeky',
      'Happy',
    ],
    ko: [
      '신비로운',
      '유쾌한',
      '발랄한',
      '도도한',
      '화사한',
      '매혹적',
      '쏘쏘한',
      '설레는',
      '깜찍한',
      '행복한',
    ],
  }
  const nouns = {
    en: [
      'Strummer',
      'Picker',
      'Plucker',
      'Diffuser',
      'Flicker',
      'Melodeon',
      'Slinger',
      'Tuner',
      'Pickiro',
      'Flutterer',
    ],
    ko: [
      '스트링',
      '피커',
      '플래터',
      '디퓨저',
      '플릭서',
      '멜로디언',
      '슬레이어',
      '튜너',
      '피키로',
      '플러터',
    ],
  }
  const adj = adjs[language][Math.floor(Math.random() * adjs[language].length)]
  const noun =
    nouns[language][Math.floor(Math.random() * nouns[language].length)]
  return `${adj} ${noun}`
}

export async function fetchUserInfo() {
  const resp = await repoutil.get(`users/${repoutil.getUserInfo()['username']}`)
  const data = await resp.json()
  const userInfo = data['data']
  return userInfo
}
