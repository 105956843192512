import React, { Fragment, useState, useEffect } from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  TextField,
  Container,
  Button,
  Image,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
// import AppleLogin from 'react-apple-login'
import AppleLogin from './AppleSignInButton'
import { createTheme } from '@mui/material/styles'
import * as repoutil from '../util/repoutil'

export default function AppleSignInCallback() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const qs = location.search
    const params = new URLSearchParams(qs)
    const doesUserExists = params.get('doesUserExists') == 'true'
    if (doesUserExists) {
      const accessToken = params.get('accessToken')
      repoutil.saveAuthentication(accessToken)
      window.location.replace(`/feed/${repoutil.getUserInfo()['username']}`)
      return
    }
    const appleToken = params.get('appleToken')
    if (appleToken == null || appleToken.length == 0) {
      alert("Failed to get Apple ID token from Apple's server")
    }
    // go to sign in page with token
    // NOTE: replace means this page will not be added to the history
    navigate(`/signup?appleToken=${appleToken}`, { replace: true })
  }, [location.search])
  return <div />
}
