import { ErrorMessage } from '@hookform/error-message'
import { ButtonGroup, Grid, Stack, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
export default function InputWithLabel(props) {
  const {
    nameInForm,
    control,
    labelValue,
    labelWidth,
    inputWidth,
    placeholder,
    orientation,
    space,
    labelProps,
    inputProps,
    inputInputProps,
    _props,
    rules,
  } = props
  return (
    <>
      {control && (
        <Controller
          control={control}
          name={nameInForm}
          rules={rules}
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <Grid
                container
                orientation={orientation}
                space={space}
                alignItems={'center'}
                {..._props}
              >
                <Grid item xs={labelWidth}>
                  <Typography {...labelProps}>{labelValue}</Typography>
                </Grid>
                <Grid item xs={inputWidth}>
                  <TextField
                    fullWidth
                    value={value}
                    placeholder={placeholder}
                    inputProps={inputInputProps}
                    onChange={onChange}
                    {...inputProps}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={nameInForm}
                    render={({ message }) => (
                      <Typography variant={'caption'} color={'error'}>
                        {`${message}`}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            )
          }}
        ></Controller>
      )}
    </>
  )
}
