import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  SyntheticEvent,
  MouseEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  TextField,
  Accordion,
  AccordionSummary,
  Chip,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  ExpandMore,
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Delete,
  CalendarTodayOutlined,
  CheckOutlined,
} from '@mui/icons-material'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { currentPracticeDayState } from '../state/practiceday'
import {
  practiceSongsState,
  retrievePracticeSongs,
} from '../state/practicesong'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  BLACK,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import {
  GrayOutlinedButtonSmall,
  OutlinedButton,
  RedOutlinedButtonSmall,
} from '../Components/Button'
import InputWithLabel from '../Components/InputWithLabel'
import { useForm, Controller } from 'react-hook-form'
import { type } from '@testing-library/user-event/dist/type'
import {
  CreatePracticeSongInputDto,
  PracticeSongOutputDto,
  SongKind,
} from '../types/practiceSong'
import { DevTool } from '@hookform/devtools'
import { ErrorMessage } from '@hookform/error-message'
import { ArtistIdentityDto, ArtistIdentityNotExistable } from '../types/artist'
import { PracticeTaskGroupIdentity } from '../types/practiceTask'
import { PracticeDayPracticeTaskGroupDto } from '../types/practiceDay'
import { capitalize } from '../util/stringutil'

export interface SongPracticeTaskGroupHeaderProps {
  song: CreatePracticeSongInputDto
}

// Execute Create, Update, Delete PracticeSong
export default function SongPracticeTaskGroupHeader({
  song,
}: SongPracticeTaskGroupHeaderProps) {
  return (
    <Stack direction="row" alignItems={'center'} spacing={1}>
      <Chip
        label="Song"
        sx={{
          backgroundColor: GREEN_LIGHTEST,
          fontSize: '14px',
        }}
      />
      <Stack direction="row" alignItems={'center'} spacing={0.5}>
        <Typography color={GRAY_DARK} fontWeight={'bold'} fontSize={'14px'}>
          {song.title}
        </Typography>
        {song.artists.length > 0 ? (
          <Typography color={GRAY_DARK} fontSize={'14px'}>
            -
          </Typography>
        ) : null}
        <Typography color={GRAY_DARK} fontSize={'14px'}>
          {song.artists.map((artist) => artist.name).join(',')}
        </Typography>
      </Stack>
    </Stack>
  )
}
