import React, { useState, useEffect, Fragment, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import {
  useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
import CustomToggleButtonGroup from './CustomToggleButtonGroup'
import PlayingJournalView from './PlayingJournalView/PlayingJournalView'
import * as repoutil from './util/repoutil'

export default function AuthenticatedRoute({ element }) {
  return repoutil.isAuthenticated() ? (
    element
  ) : (
    <Navigate to={{ pathname: '/signin' }} />
  )
}
