import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  SyntheticEvent,
  MouseEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  TextField,
  Accordion,
  AccordionSummary,
  Chip,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  ExpandMore,
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Delete,
  CalendarTodayOutlined,
  CheckOutlined,
} from '@mui/icons-material'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { currentPracticeDayState } from '../state/practiceday'
import {
  practiceSongsState,
  retrievePracticeSongs,
} from '../state/practicesong'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  BLACK,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import {
  GrayOutlinedButtonSmall,
  OutlinedButton,
  RedOutlinedButtonSmall,
} from '../Components/Button'
import InputWithLabel from '../Components/InputWithLabel'
import { useForm, Controller } from 'react-hook-form'
import { type } from '@testing-library/user-event/dist/type'
import { CreatePracticeSongInputDto, SongKind } from '../types/practiceSong'
import { DevTool } from '@hookform/devtools'
import { ErrorMessage } from '@hookform/error-message'
import { ArtistIdentityDto, ArtistIdentityNotExistable } from '../types/artist'
import PracticeTaskGroupHeader from './SongPracticeTaskGroupHeader'
import SongPracticeTaskGroupHeader from './SongPracticeTaskGroupHeader'

export interface WritePracticeSongFormValues {
  title: string
  artists: string[]
  songKind: SongKind
  taskCount: number
  musicalKeyOriginal: string
  musicalKeyArranged: string
  musicalTempoOriginal: number | null
  musicalTempoArranged: number | null
  routineCount: number
  note: string
}

const convertInputDtoToFormValues = (
  practiceSong: CreatePracticeSongInputDto,
): WritePracticeSongFormValues => {
  return {
    ...practiceSong,
    artists: practiceSong.artists.map(
      (artist: ArtistIdentityDto) => artist.name,
    ),
  }
}

export interface PracticeSongViewProps {
  practiceSongId: number
}

export function buildDefaultPracticeSong(): CreatePracticeSongInputDto {
  return {
    title: '',
    artists: [{ id: 0, name: '' }],
    songKind: 'RELEASED',
    taskCount: 0,
    musicalKeyOriginal: '',
    musicalKeyArranged: '',
    musicalTempoOriginal: null,
    musicalTempoArranged: null,
    routineCount: 0,
    note: '',
  }
}

// Execute Create, Update, Delete PracticeSong
export default function PracticeSongView({
  practiceSongId,
}: PracticeSongViewProps) {
  const [practiceSong, setPracticeSong] = useState(buildDefaultPracticeSong())
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    clearErrors,
    formState: { errors, dirtyFields },
    setError,
  } = useForm<WritePracticeSongFormValues>({
    defaultValues: convertInputDtoToFormValues(practiceSong),
    values: {
      ...practiceSong,
      artists:
        practiceSong && practiceSong.artists.map((artist) => artist.name),
    },
  })
  useEffect(() => {
    const fetchPracticeSong = async () => {
      const resp = await repoutil.get(`practiceSongs/${practiceSongId}`)
      const respData = await resp.json()
      setPracticeSong(respData.data)
    }
    fetchPracticeSong()
  }, [])

  const submitButtonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Object.keys(dirtyFields).length > 0) {
        submitButtonRef.current?.click()
      }
    }, 1000)
    console.log(
      `Start interval(id=${intervalId}) auto-saving the song(id=${practiceSongId}) every second`,
    )
    return () => {
      console.log(
        `Clear interval(id=${intervalId}) auto-saving the song(id=${practiceSongId}) every second`,
      )
      clearInterval(intervalId)
    }
  }, [])

  const onSubmit = async (data: WritePracticeSongFormValues) => {
    reset(data, { keepValues: true })
    updateSong(data)
  }

  const updateSong = async (data: WritePracticeSongFormValues) => {
    try {
      const resp = await repoutil.put(`practiceSongs/${practiceSongId}`, {
        title: practiceSong.title,
        artistIds: practiceSong.artists.map((artist) => artist.id),
        songKind: practiceSong.songKind,
        note: data.note,
        musicalKeyOriginal: data.musicalKeyOriginal,
        musicalKeyArranged: data.musicalKeyArranged,
        musicalTempoOriginal: data.musicalTempoOriginal,
        musicalTempoArranged: data.musicalTempoArranged,
      })
      if (!resp.ok) {
        setError('root.updateSongError', {
          type: 'failure',
          message: 'Status code: resp.status',
        })
        setTimeout(() => {
          clearErrors()
        }, 3000)
      }
      const respData = await resp.json()
    } catch (e: any) {
      console.error(e)
      setError('root.updateSongError', {
        type: 'failure',
        message: e.message,
      })
      setTimeout(() => {
        clearErrors()
      }, 3000)
    }

    retrievePracticeSongs()
  }
  const [isOpen, setIsOpen] = useState(false)
  const openClickHandler = (e: SyntheticEvent) => {
    setIsOpen(!isOpen)
  }

  const simpleInputs = [
    {
      labelValue: '🎵 Key',
      nameInForm: 'musicalKeyOriginal',
      type: 'text',
      placeholder: 'e.g., E Major',
    },
    {
      labelValue: '🥁 Tempo',
      nameInForm: 'musicalTempoOriginal',
      type: 'number',
      placeholder: 'in bpm. e.g., 120',
    },
  ]

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* hidden submit button */}
        <input
          type="submit"
          style={{ display: 'none' }}
          ref={submitButtonRef}
        />
        <Accordion
          elevation={0}
          expanded={isOpen}
          disableGutters={true}
          onChange={openClickHandler}
          sx={{
            paddingY: '0px',
            [`&:before`]: {
              display: 'none',
            },
            backgroundColor: 'transparent',
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{
                  color: GRAY_LIGHT,
                }}
              />
            }
            sx={{
              paddingY: 0,
              paddingX: '4px',
              minHeight: '16px',
              [`& .${accordionSummaryClasses.content}`]: {
                margin: '0px',
              },
              [`&.${accordionSummaryClasses.expanded}`]: {
                [`& .${accordionSummaryClasses.content}`]: {
                  margin: '0px',
                },
              },
            }}
          >
            <SongPracticeTaskGroupHeader song={practiceSong} />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Stack
              spacing={0}
              sx={{
                overflow: 'hidden',
                backgroundColor: 'transparent',
                paddingX: '0px',
                paddingTop: '4px',
                paddingBottom: '12px',
              }}
            >
              <Stack spacing={1}>
                {simpleInputs.map((input) => (
                  <InputWithLabel
                    {...{
                      nameInForm: input.nameInForm,
                      labelWidth: 3,
                      inputWidth: 9,
                      labelValue: input.labelValue,
                      control,
                      variant: 'outlined',
                      placeholder: input.placeholder,
                      labelProps: {
                        sx: {
                          color: GRAY_DARK,
                        },
                        fontSize: '13px',
                      },
                      inputProps: {
                        sx: {
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          '& fieldset': { border: 'none' },
                        },
                        type: input.type,
                      },
                      inputInputProps: {
                        sx: {
                          padding: '10px',
                          fontSize: '13px',
                          type: input.type,
                        },
                      },
                    }}
                  />
                ))}
                <Controller
                  control={control}
                  name="note"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      multiline
                      minRows={1}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        '& fieldset': { border: 'none' },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: '15px',
                        },
                      }}
                      variant="outlined"
                      placeholder="Note"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="updateSongError"
                  render={({ message }) => (
                    <Typography color="#F15555">{message}</Typography>
                  )}
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>

        {process.env.NODE_ENV === 'development' && (
          <DevTool control={control} placement="top-right" />
        )}
      </form>
    </Box>
  )
}
