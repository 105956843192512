import * as jjwt from 'jsonwebtoken'

const API_HOST = process.env.REACT_APP_API_SERVER_URL

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAccessToken()}`,
}

export function saveAuthentication(accessToken) {
  localStorage.setItem('accessToken', accessToken)
  console.warn(accessToken)
  const jwt = jjwt.decode(accessToken, { json: true })
  if (jwt == null) {
    console.warn('JWT object is null')
    return
  }
  localStorage.setItem('username', jwt.sub)
  localStorage.setItem('nickname', jwt['nickname'])

  return
}
export function deleteAuthentication() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('username')
  localStorage.removeItem('nickname')

  return
}

export function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export function getUserInfo() {
  return {
    username: localStorage.getItem('username'),
    nickname: localStorage.getItem('nickname'),
  }
}

export function isAuthenticated() {
  const token = getAccessToken()
  if (token == undefined) {
    console.warn('failed to get token')
    return false
  }
  const jwt = jjwt.decode(token, { json: true })
  if (jwt == null) {
    console.warn('JWT object is null')
    return false
  }
  const currentTimestamp = Math.floor(Date.now() / 1000) // Current time in seconds
  if (jwt.exp < currentTimestamp) {
    console.warn(`JWT is expired. current=${currentTimestamp}, exp=${jwt.exp}`)
    return false
  }

  return true
}
export function http(endpoint, method = 'GET', headers = {}, body = null) {
  const options = {
    method: method,
    headers: { ...DEFAULT_HEADERS, ...headers },
  }

  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    if (options['headers']['Content-Type'] == 'application/json') {
      options['body'] = JSON.stringify(body)
    } else {
      if (options['headers']['Content-Type'] == 'multipart/form-data') {
        delete options['headers']['Content-Type']
      }
      options['body'] = body
    }
  }

  return fetch(API_HOST + endpoint, options)
}

export function post(endpoint, body) {
  return http(endpoint, 'POST', {}, body)
}
export function put(endpoint, body) {
  return http(endpoint, 'PUT', {}, body)
}
export function patch(endpoint, body) {
  return http(endpoint, 'PATCH', {}, body)
}
export function del(endpoint, body) {
  return http(endpoint, 'DELETE', {}, body)
}
export function get(endpoint) {
  return http(endpoint, 'GET', {}, {})
}
