const APPLE_SIGN_IN_CLIENT_ID = process.env.REACT_APP_APPLE_SIGN_IN_CLIENT_ID
const APPLE_SIGN_IN_REDIRECT_URI =
  process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URI
const APPLE_SIGN_IN_SCOPES = ['name', 'email'].join(' ')
const APPLE_SIGN_IN_STATE = 'foo-state'
const APPLE_SIGN_IN_USE_POP_UP = false
const AppleLogin = () => {
  const loginWithApple = async (e) => {
    e.preventDefault()

    console.log('sign in with apple')
    //appleid.apple.com/auth/authorize?client_id=app.musicjournals.me&redirect_uri=https%3A%2F%2Fapi.mj.jinsu.me%2Fauth%2Fapple%2Fcallback&response_type=code%20id_token&state=foo-state&scope=name%2Cemail&nonce=4952&response_mode=form_post&frame_id=b308fe8d-d6da-4bcb-a274-3ad0cf2c87f1&m=11&v=1.5.5
    https: window.AppleID.auth.init({
      clientId: APPLE_SIGN_IN_CLIENT_ID,
      scope: APPLE_SIGN_IN_SCOPES,
      redirectURI: APPLE_SIGN_IN_REDIRECT_URI,
      state: APPLE_SIGN_IN_STATE,
      nonce: `${Math.floor(Math.random() * 10000)}`,
      usePopup: APPLE_SIGN_IN_USE_POP_UP,
    })

    try {
      const res = await window.AppleID.auth.signIn()
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <img
      src="/apple-sign-in-230px-36px.png"
      width="100%"
      onClick={loginWithApple}
    />
  )
}

export default AppleLogin
