import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
  ReactNode,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import Select from 'react-select'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  ButtonProps,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { SelectOption, YYYYMMDDDateString } from '../types/common'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateSongPracticeTaskFormData,
  CreateSongPracticeTaskFormDataSchema,
} from './common'
import { PracticeSongOutputDto } from '../types/practiceSong'
import { GradientButton } from '../Components/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GRAY_LIGHT,
  GREEN,
  GREEN_800,
  GREEN_LIGHT,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { ErrorMessage } from '@hookform/error-message'

export interface AddSongTaskFormViewProps {
  date: YYYYMMDDDateString
  onSubmit: (data: CreateSongPracticeTaskFormData) => void
}

export default function AddSongTaskFormView({
  date,
  onSubmit,
}: AddSongTaskFormViewProps) {
  const { handleSubmit, control, register } =
    useForm<CreateSongPracticeTaskFormData>({
      resolver: zodResolver(CreateSongPracticeTaskFormDataSchema),
    })
  // validation

  const _onSubmit = async (formData: CreateSongPracticeTaskFormData) => {
    const resp = await repoutil.post('practiceTasks', {
      ...formData,
      practiceTaskCategory: 'SONG',
      practiceSongId: formData.practiceSongId,
      date: date,
      todo: {
        goalDuration: 0,
        isDone: false,
      },
    })
    const data = await resp.json()
    console.log(data)
    onSubmit(formData)
  }

  const [songOptions, setSongOptions] = useState<SelectOption<number>[]>([])

  const fetchSongs = async () => {
    const resp = await repoutil.http(`practiceSongs?songKind=RELEASED`)
    const data = await resp.json()
    setSongOptions(
      (data.data as PracticeSongOutputDto[]).map((song) => ({
        value: song.id,
        label: expressionutil.songTitleWithArtists(song),
      })),
    )
    return data.data
  }

  useEffect(() => {
    fetchSongs()
  }, [])

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Box className="form-input-box" py={3} minHeight={'200px'}>
        <Controller
          control={control}
          name="practiceSongId"
          render={({
            field: { name, onChange, value, ref },
            formState: { errors },
          }) => (
            <>
              <Select<SelectOption<number>>
                placeholder="Select a song"
                ref={ref}
                value={songOptions.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                options={songOptions}
                menuPosition="fixed"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    // Generated by ChatGPT based on the primary color #34C61C
                    primary: '#34C61C', // Your primary green color
                    primary75: '#4FDA36', // Slightly lighter green (75% transparency)
                    primary50: '#8FF285', // Even lighter green (50% transparency)
                    primary25: '#BFF7BA', // Lightest green (25% transparency)
                    neutral0: '#ffffff', // Background color (white)
                    neutral5: '#f6f6f6', // Light grey for subtle elements
                    neutral10: '#e6e6e6', // Light grey for borders
                    neutral20: '#cccccc', // Border color
                    neutral30: '#b3b3b3', // Hover border color
                    neutral40: '#999999', // Disabled text color
                    neutral50: '#666666', // Default text color
                    neutral60: '#4d4d4d', // Active text color
                    neutral70: '#333333', // Hover text color
                    neutral80: '#1a1a1a', // Focused text color
                    neutral90: '#0d0d0d', // Darkest text color
                    danger: '#d9534f', // Danger color (red)
                    dangerLight: '#f2dede', // Light danger color
                  },
                })}
                styles={{
                  menuList: (base) => ({ ...base, maxHeight: '150px' }),
                }}
              />

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Typography variant={'caption'} color={'error'}>
                    {`${message}`}
                  </Typography>
                )}
              />
            </>
          )}
        />
        <Controller
          control={control}
          name="title"
          render={({ field, formState: { errors } }) => (
            <>
              <TextField
                id="titleInput"
                placeholder="Title"
                fullWidth={true}
                margin="dense"
                inputProps={{
                  style: { paddingTop: '8px', paddingBottom: '8px' },
                }}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name={field.name}
                render={({ message }) => (
                  <Typography variant={'caption'} color={'error'}>
                    {`${message}`}
                  </Typography>
                )}
              />
            </>
          )}
        />
        {/* <Typography>최근 추가한 연습</Typography> */}
      </Box>
      <GradientButton
        fullWidth
        type="submit"
        variant="contained"
        style={{
          fontSize: '16px',
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: 'bold',
        }}
        disableElevation={true}
      >
        Create
      </GradientButton>
    </form>
  )
}
