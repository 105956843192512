import React, { Fragment, useState, useEffect, SyntheticEvent } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  Container,
  Button,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'

import * as dateutil from './util/dateutil'
import * as repoutil from './util/repoutil'
import { currentPracticeDayState } from './state/practiceday'
import { useRecoilState } from 'recoil'
import { YYYYMMDDDateString } from './types/common'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'

// dates는 이 주의 날짜들을 의미.
export interface CalendarWeeklyViewProps {
  dates: Date[]
}

export default function CalendarWeeklyView({ dates }: CalendarWeeklyViewProps) {
  const [currentPracticeDay, setCurrentPracticeDay] = useRecoilState(
    currentPracticeDayState,
  )

  const [practiceDaysOfWeek, setPracticeDaysOfWeek] = useState<
    PracticeDayDetailedOutputDto[]
  >([])

  const dateOfToday = dateutil.formatDate(new Date())

  const getColorOfDailyBox = (cnt = 0) => {
    const minColor = ['FF', 'FF', 'FF']
    const maxColor = ['24', 'B6', '0C']
    const emptyColor = '#D9D9D9'

    if (cnt == 0) {
      return emptyColor
    }
    const maxDuration = 10
    const resultColor = [] as string[]
    minColor
      .map((e, i) => [e, maxColor[i]])
      .forEach(([min, max]) => {
        const totalColorDiff = parseInt(min, 16) - parseInt(max, 16)
        const colorDiff = Math.floor(
          totalColorDiff * Math.min(cnt / maxDuration, 1),
        )
        resultColor.push(
          (parseInt(min, 16) - colorDiff).toString(16).padStart(2, '0'),
        )
      })
    return '#' + resultColor.join('')
  }

  const retrievePracticeDay = async (dateString: YYYYMMDDDateString) => {
    const resp = await repoutil.http(`practiceDays/${dateString}`)
    const data = await resp.json()
    return data.data
  }

  // 하루에 대한 연습 기록을 조회
  const fetchPracticeDay = async (dateString: YYYYMMDDDateString) => {
    setCurrentPracticeDay(await retrievePracticeDay(dateString))
  }

  const fetchWeeklyPractice = async () => {
    try {
      const promises = dates.map((date) =>
        retrievePracticeDay(dateutil.formatDate(date)),
      )
      const results = await Promise.all(promises)
      const practiceDaysOfWeek = results.map((practiceDay, i) => {
        return practiceDay
      })
      setPracticeDaysOfWeek(practiceDaysOfWeek)
    } catch (err) {
      console.error('Error fetching practice days: ', err)
    }
  }

  useEffect(() => {
    fetchWeeklyPractice()
  }, [])

  const getPracticeCountOfDay = (pd: PracticeDayDetailedOutputDto) => {
    return pd.practiceTaskGroups.reduce((total, group) => {
      return (
        total +
        group.practiceTasks.reduce((songTotal, task) => {
          return songTotal + (task.todo.isDone ? 1 : 0)
        }, 0)
      )
    }, 0)
  }

  const generateOnClickDateHandler =
    (dateString: YYYYMMDDDateString) => async (e: SyntheticEvent) => {
      await fetchPracticeDay(dateString)
    }

  const getBoxShadow = (date: YYYYMMDDDateString) => {
    return dateOfToday == date ? '0 0 0 2px #33A000 inset' : null
  }

  return (
    <Stack
      m={10}
      direction={'row'}
      spacing={1.5}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {practiceDaysOfWeek.map((pd, i) => {
        return (
          <Box
            key={i}
            sx={{
              backgroundColor: getColorOfDailyBox(getPracticeCountOfDay(pd)),
              width: '28px',
              height: '28px',
              borderRadius: '4px',
              boxShadow: getBoxShadow(pd.date),
              display: 'flex',
            }}
            onClick={generateOnClickDateHandler(pd.date)}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography fontStyle={{ textAlign: 'center' }}>
              {pd.date == currentPracticeDay.date ? '✅' : ''}
            </Typography>
          </Box>
        )
      })}
    </Stack>
  )
}
