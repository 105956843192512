import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
} from '@mui/icons-material'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import {
  GRAY_LIGHT,
  GRAY,
  GRAY_DARK,
  GRAY_LIGHTEST,
  GRAY_DARKEST,
  GREEN,
  GREEN_800,
} from '../theme/theme'

export default function SettingsItemButton({ onClick, variant, value }) {
  const color = () => {
    switch (variant) {
      case 'disabled':
        return GRAY
      // case 'primary':
      //   return GREEN
      default:
        return GRAY_DARKEST
    }
  }
  const fontWeight = () => {
    switch (variant) {
      case 'primary':
        return 'bold'
      default:
        return 'normal'
    }
  }

  return (
    <Box onClick={onClick}>
      <Typography color={color()} pl="30px" fontWeight={fontWeight()}>
        {value}
      </Typography>
    </Box>
  )
}
