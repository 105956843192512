import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  CheckOutlined,
  CalendarTodayOutlined,
  AccessTimeIcon,
  SentimentSatisfied,
} from '@mui/icons-material'
import CustomToggleButtonGroup from '../CustomToggleButtonGroup'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { currentPracticeDayState } from '../state/practiceday'
import { practiceSongsState } from '../state/practicesong'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import BasePage from '../BasePage'

export default function PracticeSongListPage() {
  const [songs, setSongs] = useRecoilState(practiceSongsState)

  const navigate = useNavigate()

  const onClickCreatePracticeSong = (e) => {
    navigate(getCreateSongPageUrl(), { state: { practiceSong: null } })
  }
  const generateOnClickPracticeSongHandler = (practiceSong) => (e) => {
    navigate(getUpdateSongPageUrl(), { state: { practiceSong: practiceSong } })
  }

  const onBoarding = () => (
    <Stack
      className="mt-12"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
      spacing={2}
    >
      <SentimentSatisfied style={{ color: GRAY_DARK, fontSize: '48px' }} />
      <Typography color={GRAY_DARK} textAlign={'center'}>
        There's no practice song added yet!
        <br />
        Please add a practice song
        <br />
        by clicking the Add button above.
      </Typography>
    </Stack>
  )

  const songList = () => (
    <Stack spacing={1.5} alignItems={'center'} sx={{ width: '100%' }}>
      {songs.map((song) => (
        <Stack
          key={song.id}
          direction={'column'}
          sx={{
            borderRadius: '10px',
            backgroundColor: 'white',
            width: '100%',
          }}
          onClick={generateOnClickPracticeSongHandler(song)}
          p={1.5}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Typography fontSize={'16px'}>{song.title}</Typography>{' '}
            <Typography
              fontSize={'14px'}
              color={GRAY}
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {' - '}
            </Typography>
            <Typography fontSize={'14px'} color={GRAY}>
              {song.artists.map((artist) => artist.name).join(', ')}
            </Typography>
          </Stack>

          <Stack direction={'row'} paddingLeft={2.5} spacing={2} color={GRAY}>
            <Stack
              direction={'row'}
              style={{
                backgroundColor: 'white',
                borderRadius: '10px',
              }}
              spacing={1}
              alignItems={'center'}
            >
              <CalendarTodayOutlined sx={{ color: GRAY }} fontSize="14px" />
              <Typography color={GRAY_DARK} fontSize="14px">
                {song.dayCount} days
              </Typography>
            </Stack>

            <Stack
              direction={'row'}
              style={{
                backgroundColor: 'white',
                borderRadius: '10px',
              }}
              spacing={1}
              alignItems={'center'}
            >
              <CheckOutlined sx={{ color: GRAY }} fontSize="14px" />
              <Typography color={GRAY_DARK} fontSize="14px">
                {song.taskCount} practices
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )

  return (
    <BasePage
      child={songs.length === 0 ? onBoarding() : songList()}
      stackProps={{ spacing: 1.5 }}
      appTitleBarProps={{
        rightCornerNode: (
          <Typography onClick={onClickCreatePracticeSong} color={GRAY_DARKEST}>
            Add
          </Typography>
        ),
      }}
    />
  )
}
