import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { faClock, faNoteSticky } from '@fortawesome/free-regular-svg-icons'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Alert,
  Card,
  Chip,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Visibility,
  VisibilityOff,
  Note,
  AccessTime,
  PlayArrow,
  Pause,
  Close,
  PushPin,
} from '@mui/icons-material'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import TaskView from '../TaskView/TaskView'
import TaskViewReadonly from '../TaskView/TaskViewReadonly'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as timeutil from '../util/timeutil'
import * as expressionutil from '../util/expressionutil'
import {
  currentPracticeDayState,
  retrievePracticeDay,
} from '../state/practiceday'
import {
  GRADIENT_LIGHT_GREEN,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { OutlinedButton } from '../Components/Button'
import PracticeSongView from '../Practice/PracticeSongView'

import { PracticeTaskOutputDto } from '../types/practiceTask'
import PracticeTaskGroupHeader from '../Practice/SongPracticeTaskGroupHeader'
import { PracticeDayPracticeTaskGroupDto } from '../types/practiceDay'
import SongPracticeTaskGroupHeader from '../Practice/SongPracticeTaskGroupHeader'
import NonSongPracticeTaskGroupHeader from '../Practice/NonSongPracticeTaskGroupHeader'
import { PracticeTaskCategory } from './common'
import { PracticeNoteOutputDto } from '../types/practiceNote'
import { on } from 'events'
import PracticeNoteListView, {
  PracticeNoteListQueryType,
} from '../PracticeNoteListView'
import PracticeSongListView from '../Practice/PracticeTaskGroupListView'

// date props는 new Date()와 동일한 타입
export default function PracticeDayView() {
  const [practiceDay, setPracticeDay] = useRecoilState(currentPracticeDayState)

  const navigate = useNavigate()
  // date args는 new Date()와 동일한 데이터 타입
  // 이 메소드를 언제 쓸지는 고민
  const compareDateWithToday = (date: Date) => {
    const today = new Date()
    if (!date) {
      console.error('date is falsy', date)
    }

    const todayString = dateutil.formatDate(today)
    const dateString = dateutil.formatDate(date)

    if (dateString == todayString) return 0
    else if (dateString < todayString) return -1
    else return 1
  }

  const handleClick = (e: SyntheticEvent) => {
    navigateToPracticePage()
  }

  const navigateToPracticePage = () => {
    navigate(`/practice`, {
      state: {},
    })
  }

  const PracticeDayStateView = (
    <Grid
      container
      p={1}
      pt={2}
      // Without note, 1 feels too narrow.
      pb={1}
      borderRadius={2}
      sx={{ background: GRADIENT_LIGHT_GREEN }}
      rowGap={2}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems={'center'}
      >
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faClock}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'18px'}
            textAlign={'center'}
            color={GREEN}
            fontWeight={'bold'}
          >
            {timeutil.formatDuration(
              ...timeutil.convertMsToDuration(
                practiceDay.practiceDuration * 1000,
              ),
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faNoteSticky}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                fontSize: '18px',
                color: GREEN,
                fontWeight: 'bold',
              }}
            >
              {practiceDay.practiceNotes.length}
            </span>
            {' Posts'}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faMusic}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                fontSize: '18px',
                color: GREEN,
                fontWeight: 'bold',
              }}
            >
              {practiceDay.practiceTaskGroups.length}
            </span>
            {' Songs'}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faListCheck}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                color: GREEN,
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {practiceDay.practiceTaskGroups.reduce(
                (acc: number, practiceTaskGroup) =>
                  acc +
                  practiceTaskGroup.practiceTasks.filter(
                    (task) => task.todo.isDone,
                  ).length,
                0,
              )}
            </span>
            {' Practice'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container>
      {/* Header container */}
      <Grid item xs={12}></Grid>
      {/* Body container */}
      <Grid item xs={12}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            {PracticeDayStateView}
          </Grid>
          {practiceDay.practiceNotes.length > 0 && (
            <Grid item xs={12}>
              <PracticeNoteListView
                date={practiceDay.date}
                queryType={PracticeNoteListQueryType.DATE_ONLY}
              />
            </Grid>
          )}
          <Grid item xs={12} onClick={handleClick}>
            <PracticeSongListView
              practiceTaskGroups={practiceDay.practiceTaskGroups}
              fallback={
                <OutlinedButton
                  onClick={navigateToPracticePage}
                  fullWidth
                  disableElevation={true}
                  type="button"
                  variant="outlined"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'normal',
                    borderRadius: '8px',
                    color: GREEN,
                  }}
                >
                  Go to practice
                </OutlinedButton>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Footer container */}
      <Grid item xs={12}>
        <Grid container></Grid>
      </Grid>
    </Grid>
  )
}
