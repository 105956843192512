import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Stack,
  Container,
  Paper,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Input from '@mui/material/Input'
import VolumeUp from '@mui/icons-material/VolumeUp'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  CameraAlt,
  Menu,
} from '@mui/icons-material'
import { useNavigate, useHistory } from 'react-router-dom'

import * as repoutil from '../util/repoutil'
import TaskView from '../TaskView/TaskView'
import * as dateutil from '../util/dateutil'
import { useParams } from 'react-router'
import { getHomeUrl } from '../config/config'

const MEDIA_TYPE_VIDEO = 'video'
const MEDIA_TYPE_IMAGE = 'image'
const TASK_DEFAULT_DURATION = 20
const DEFAULT_TITLE = dateutil.formatDate(new Date())
const DEFAULT_TASK = {
  id: '0',
  title: '',
  duration: '',
  doneAt: null,
}
const DEFAULT_WRITE_PLAYING_JOURNAL_INPUT = {
  title: '',
  description: '',
  tasks: [],
}
export default function WritePlayingJournal({
  writePlayingJournalInputProps = DEFAULT_WRITE_PLAYING_JOURNAL_INPUT,
  mediaSourcesProps = [],
  match,
  isCreate = true,
}) {
  const { playingJournalId } = useParams()
  const [writePlayingJournalInput, setWritePlayingJournalInput] = useState(
    writePlayingJournalInputProps,
  )
  console.log(writePlayingJournalInput)

  const mediaInput = useRef(null)
  const [mediaSources, setMediaSources] = useState(mediaSourcesProps)
  const [mediaFiles, setMediaFiles] = useState([])
  const navigate = useNavigate()

  const generateInputChangeHandler = (key) => {
    return (event) =>
      setWritePlayingJournalInput({
        ...writePlayingJournalInput,
        [key]: event.target.value,
      })
  }
  const generateTaskTitleChangeHandler = (i) => {
    return (event) => {
      writePlayingJournalInput.tasks[i].title = event.target.value
      setWritePlayingJournalInput({
        ...writePlayingJournalInput,
      })
    }
  }
  const generateTaskDurationChangeHandler = (i) => {
    return (event) => {
      writePlayingJournalInput.tasks[i].duration = event.target.value
      setWritePlayingJournalInput({
        ...writePlayingJournalInput,
      })
    }
  }
  const addTask = () => {
    writePlayingJournalInput.tasks.push({ ...DEFAULT_TASK })
    setWritePlayingJournalInput({
      ...writePlayingJournalInput,
    })
  }
  const deleteTask = (task) => {
    const i = writePlayingJournalInput.tasks.indexOf(task)
    if (i != -1) {
      writePlayingJournalInput.tasks.splice(i, 1)
      setWritePlayingJournalInput({
        ...writePlayingJournalInput,
      })
    }
  }

  const onClickBack = () => {
    navigate(-1)
  }
  const onSubmit = async () => {
    console.log(match)
    const resp = await repoutil.http(
      isCreate ? 'playingJournals' : `playingJournals/${playingJournalId}/edit`,
      //   isCreate ? 'POST' : 'PUT',
      'POST',
      {
        'Content-Type': 'multipart/form-data',
      },
      createFormData(),
    )
    const data = await resp.json()
    console.log(data)
    // TODO: Improve this with better navigating logics
    window.location.replace(getHomeUrl())
  }

  const createFormData = () => {
    const formData = new FormData()
    const title =
      writePlayingJournalInput['title'] != ''
        ? writePlayingJournalInput['title']
        : DEFAULT_TITLE
    formData.append('title', title)
    const description = writePlayingJournalInput['description'].replace(
      /\r\n/g,
      '\n',
    )
    formData.append('description', writePlayingJournalInput['description'])
    for (const i in writePlayingJournalInput['tasks']) {
      const task = writePlayingJournalInput['tasks'][i]
      if (task.duration == '') {
        task.duration = TASK_DEFAULT_DURATION
      }
      formData.append(`tasks[${i}].id`, task.id)
      formData.append(`tasks[${i}].title`, task.title)
      formData.append(`tasks[${i}].duration`, `${task.duration}`)
      if (task.doneAt != null) {
        formData.append(`tasks[${i}].doneAt`, task.doneAt)
      }
    }

    if (!isCreate) return formData

    for (const i in mediaFiles) {
      const mediaFile = mediaFiles[i]
      //   formData.append(`mediaFiles[${i}]`, mediaFile, mediaFile.name)
      formData.append(`mediaFiles[]`, mediaFile, mediaFile.name)
    }
    return formData
  }

  const handleMediaChange = (event) => {
    if (event.target.files && event.target.files.length != 0) {
      // event.target.files is a FileList and it's not a real array.
      // So converting is needed.
      for (const file of Array.from(event.target.files)) {
        const mediaType = mediaSources.push({
          mediaType: event.target.files[0].type.includes('video')
            ? MEDIA_TYPE_VIDEO
            : MEDIA_TYPE_IMAGE,
          url: URL.createObjectURL(file),
        })
        mediaFiles.push(file)
      }
      setMediaSources([...mediaSources])
      setMediaFiles([...mediaFiles])
    }
  }

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: 0,
        }}
      >
        <Toolbar>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <ArrowBackIosOutlined onClick={onClickBack} />
          </IconButton>
          {/* <Avatar sx={{ width: 40, height: 40, backgroundColor: '#FFFFFF' }}>
            🎸
          </Avatar> */}
          {/* Text in the center */}
          <Box
            container
            justify="center"
            alignItems="center"
            style={{ flexGrow: 1, marginRight: '10px', marginLeft: '10px' }}
          >
            <Typography fontSize={16} textAlign={'center'}>
              Writing a journal
            </Typography>
          </Box>

          {/* Icon on the right */}
          <IconButton edge="end" onClick={onSubmit}>
            <Create />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container>
        <Stack spacing={2} direction={'column'}>
          <TextField
            id="title"
            placeholder={DEFAULT_TITLE}
            variant="standard"
            inputProps={{
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
              },
            }}
            value={writePlayingJournalInput['title']}
            onChange={generateInputChangeHandler('title')}
          />
          <Stack spacing={1}>
            {writePlayingJournalInput.tasks.map((task, i) => (
              <TaskView
                key={i}
                task={task}
                readonly={false}
                handleTitleChange={generateTaskTitleChangeHandler(i)}
                handleDurationChange={generateTaskDurationChangeHandler(i)}
                taskDefautlDuration={TASK_DEFAULT_DURATION}
                onClickDelete={() => deleteTask(task)}
              />
            ))}
          </Stack>
          <TextField
            id="description"
            multiline
            rows={5}
            placeholder="Let's review your day with the guitar!"
            variant="filled"
            inputProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={writePlayingJournalInput['description']}
            onChange={generateInputChangeHandler('description')}
          />
          <Stack direction={'row'} spacing={2} display="flex-start">
            <Box
              display={isCreate ? 'flex' : 'none'}
              justifyContent="center"
              alignItems="center"
              backgroundColor="#F9F9F9"
              borderRadius={'16px'}
              width={'96px'}
              minWidth={'96px'}
              height={'96px'}
              onClick={() => mediaInput.current.click()}
            >
              <CameraAlt sx={{ color: '#D8D8D8' }} />
            </Box>
            <input
              type="file"
              id="file"
              accept="image/*|video/*"
              ref={mediaInput}
              multiple={true}
              onChange={handleMediaChange}
              style={{ display: 'none' }}
            />
            <Stack
              xs={'flex'}
              direction={'row'}
              overflow={'auto'}
              style={
                isCreate
                  ? {}
                  : {
                      marginLeft: '0px',
                    }
              }
            >
              {mediaSources.map((mediaSource, i) => (
                <Box key={i} display={'flex'}>
                  <img
                    src={mediaSource.url}
                    width={'96px'}
                    height={'96px'}
                    style={{
                      objectFit: 'cover',
                      borderRadius: '16px',
                    }}
                  />
                </Box>
              ))}
            </Stack>
          </Stack>
          <Typography color={'#898989'}>
            🧪 Uploading media is an experimental feature.
            <br />
            Videos longer than 1 minute or larger than 100MB may fail to be
            uploaded.
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}
