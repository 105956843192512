import React, { Fragment, useState, useEffect } from 'react'
import '../App.css'
import { buttonClasses } from '@mui/material/Button'
import { createTheme } from '@mui/material/styles'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })
export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.sizeLarge}`]: {
            borderRadius: '10px',
            fontSize: '18px',
          },
          [`&.${buttonClasses.contained}`]: { color: '#FFFFFF' },
        },
      },
    },
  },
  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3'),
    green: augmentColor({
      color: {
        light: '#92f28f',
        main: '#34c61c',
        dark: '#248c26',
        contrastText: '#8f230d',
      },
    }),
    primary: augmentColor({
      color: {
        light: '#92f28f',
        main: '#34c61c',
        dark: '#248c26',
        contrastText: '#8f230d',
      },
    }),
    black: augmentColor({
      color: {
        light: '#D9D9D9',
        main: '#393939',
        dark: '#000000',
        contrastText: '#393939',
      },
    }),
    gray: {
      normal: '#AABB55',
    },
  },
})

export const GRAY_LIGHTEST = '#F9F9F9'
export const GRAY_LIGHT = '#B9B9B9'
export const GRAY = '#9A9A9A'
export const GRAY_DARK = '#767676'
export const GRAY_DARKEST = '#4A4A4A'
export const BLACK = '#000000'

export const GREEN_LIGHTEST = '#e4f8df'
export const GREEN_LIGHT = '#92f28f'
export const GREEN = '#34C61C'
export const GREEN_800 = '#0D5D00'
export const GRADIENT_LIGHT_GREEN =
  'linear-gradient(to bottom right, #E1F7DC, #F6EFE1)'
