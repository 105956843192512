import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MoreIcon from '@mui/icons-material/MoreVert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CustomToggleButtonGroup from '../CustomToggleButtonGroup';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const NotificationBox = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2), 
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

export default function NotificationList(props) {
  const [notifications, setNotifications] = useState(props["notifications"])
  const handleChange = (event, i) => {
      notifications.remove(i)
      setNotifications(notifications);
  };
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {notifications.map((notification) => (
        <ListItem
          key={notification}
          disableGutters
          secondaryAction={
            <IconButton aria-label="delete">
              <CloseIcon />
            </IconButton>
          }
        >
        <ListItemText primary={
            <NotificationBox>
                <Typography>
                    {notification.kind == "notification" ? "📣" : ""}
                    {notification.body}
                </Typography>
            </NotificationBox>
        } />
        </ListItem>
      ))}
    </List>
  );
}