import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  SyntheticEvent,
  MouseEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  TextField,
  Accordion,
  AccordionSummary,
  Chip,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  ExpandMore,
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Delete,
  CalendarTodayOutlined,
  CheckOutlined,
} from '@mui/icons-material'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { currentPracticeDayState } from '../state/practiceday'
import {
  practiceSongsState,
  retrievePracticeSongs,
} from '../state/practicesong'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  BLACK,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import {
  GrayOutlinedButtonSmall,
  OutlinedButton,
  RedOutlinedButtonSmall,
} from '../Components/Button'
import InputWithLabel from '../Components/InputWithLabel'
import { useForm, Controller } from 'react-hook-form'
import { type } from '@testing-library/user-event/dist/type'
import { CreatePracticeSongInputDto, SongKind } from '../types/practiceSong'
import { DevTool } from '@hookform/devtools'
import { ErrorMessage } from '@hookform/error-message'
import { ArtistIdentityDto, ArtistIdentityNotExistable } from '../types/artist'
import PracticeTaskGroupHeader from './SongPracticeTaskGroupHeader'
import SongPracticeTaskGroupHeader from './SongPracticeTaskGroupHeader'
import { YYYYMMDDDateString } from '../types/common'
import NonSongPracticeTaskGroupHeader from './NonSongPracticeTaskGroupHeader'
import TaskViewReadonly from '../TaskView/TaskViewReadonly'
import {
  PracticeDayDetailedOutputDto,
  PracticeDayPracticeTaskGroupDto,
} from '../types/practiceDay'
import { PracticeTaskCategory } from '../PracticeDayView/common'
import TaskView, { onClickDeleteTask, onTaskDone } from '../TaskView/TaskView'
import PracticeSongView from './PracticeSongView'

export interface PracticeTaskGroupListViewProps {
  practiceTaskGroups: PracticeDayPracticeTaskGroupDto[]
  fallback?: JSX.Element
  readonly?: boolean
  onClickDeleteTask?: onClickDeleteTask
  onTaskDone?: onTaskDone
}

// Execute Create, Update, Delete PracticeSong
export default function PracticeTaskGroupListView({
  practiceTaskGroups,
  fallback,
  readonly = true,
  onClickDeleteTask = () => {},
  onTaskDone = () => {},
}: PracticeTaskGroupListViewProps) {
  return (
    <>
      {practiceTaskGroups.length > 0 ? (
        <Stack spacing={2}>
          {practiceTaskGroups.map((practiceTaskGroup, i) => (
            <Grid
              container
              key={`group-${JSON.stringify(practiceTaskGroup.groupIdentity)})}`}
              rowGap={0.6}
            >
              <Grid item xs={12}>
                {practiceTaskGroup.groupIdentity.practiceTaskCategory ===
                  PracticeTaskCategory.Enum.SONG &&
                practiceTaskGroup.practiceSong ? (
                  readonly ? (
                    <SongPracticeTaskGroupHeader
                      song={practiceTaskGroup.practiceSong}
                    />
                  ) : (
                    <PracticeSongView
                      practiceSongId={practiceTaskGroup.practiceSong.id}
                    />
                  )
                ) : (
                  <NonSongPracticeTaskGroupHeader group={practiceTaskGroup} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1} width={'100%'}>
                  {practiceTaskGroup.practiceTasks.map((task, i) =>
                    readonly ? (
                      <TaskViewReadonly
                        key={'practiceTask' + task.id}
                        task={task}
                      />
                    ) : (
                      <TaskView
                        key={'practiceTask' + task.id}
                        _task={task}
                        onClickDeleteTask={onClickDeleteTask}
                        onTaskDone={onTaskDone}
                      />
                    ),
                  )}
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      ) : (
        fallback
      )}
    </>
  )
}
