// returns [from, to]
export function getDateRange() {
  const currentDate = new Date()
  const closestSaturday = new Date()
  const saturdayIndex = 6
  closestSaturday.setDate(
    currentDate.getDate() + (saturdayIndex - currentDate.getDay()),
  )
  const from = new Date(closestSaturday)
  from.setDate(from.getDate() - 27)

  // dates array contains from the `from` to `closestSaturday`
  const dates = []
  const cursor = new Date(from)
  while (cursor <= closestSaturday) {
    dates.push(new Date(cursor))
    cursor.setDate(cursor.getDate() + 1)
  }

  return dates
}

export function getCurrentDateRange() {
  const currentDate = new Date()
  const daysFromSunday = currentDate.getDay() // 0: Sunday, 1: Monday, ..., 6: Saturday
  // Set the date to the closest Sunday
  const closestSunday = new Date(currentDate)
  closestSunday.setDate(currentDate.getDate() - daysFromSunday)

  const to = new Date(closestSunday)
  to.setDate(closestSunday.getDate() + 6)

  return [closestSunday, to]
}

export function getWeekEndDate(startDate) {
  const endDate = new Date(startDate)
  endDate.setDate(startDate.getDate() + 6) // Add 6 days to get Saturday

  return endDate
}

export function formatDate(date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function formatDateToHumanString(date) {
  const day = date.getDate()
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  // Add suffix to the day
  const dayWithSuffix = addHumanSuffixToDay(day)

  return `${dayWithSuffix} ${monthNames[monthIndex]} ${year}`
}

function addHumanSuffixToDay(day) {
  if (day >= 11 && day <= 13) {
    return day + 'th'
  }
  switch (day % 10) {
    case 1:
      return day + 'st'
    case 2:
      return day + 'nd'
    case 3:
      return day + 'rd'
    default:
      return day + 'th'
  }
}
