import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Dialog,
  DialogContent,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Close,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import { currentPracticeDayState } from './state/practiceday'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import { GRAY, GRAY_LIGHTEST } from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import Carousel from 'react-material-ui-carousel'
import OnBoardingDaialog from './OnBoardingDialog'
import BasePage from './BasePage'

export default function FeedPage() {
  const [dates, setDates] = useState(dateutil.getDateRange() as Date[])
  const [currentPracticeDay, setCurrentPracticeDay] = useRecoilState(
    currentPracticeDayState,
  )
  const navigate = useNavigate()
  const navigateToWritePostPage = () => {
    navigate(`/posts/write?date=${currentPracticeDay.date}`)
  }

  return (
    <>
      <OnBoardingDaialog />
      <BasePage
        child={
          <>
            <CalendarView dates={dates} />
            <div style={{ display: 'flex' }}>
              <Typography
                textAlign={'left'}
                fontWeight={'bold'}
                marginBottom={0.5}
                flexGrow={1}
              >
                {dateutil.formatDateToHumanString(
                  new Date(currentPracticeDay.date),
                )}
              </Typography>
              <Typography
                color={GRAY}
                fontSize="16px"
                className="underline"
                textAlign={'center'}
                onClick={navigateToWritePostPage}
              >
                Write a post
              </Typography>
            </div>
            <PracticeDayView />
          </>
        }
      />
    </>
  )
}
