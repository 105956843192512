// export function formatDuration(minute) {
//   if (minute === 0) return ''

//   const hourUnit = 60
//   const dayUnit = hourUnit * 24

//   const day = Math.floor(minute / dayUnit)
//   const hour = Math.floor((minute - dayUnit * day) / hourUnit)
//   const min = minute - dayUnit * day - hourUnit * hour

//   const ret = []
//   if (day !== 0) ret.push(`${day}d`)
//   if (hour !== 0) ret.push(`${hour}h`)
//   if (min !== 0) ret.push(`${min}m`)

//   return ret.join(' ')
// }

export function convertMsToDuration(ms) {
  let delta = Math.abs(ms)
  const hours = Math.floor(delta / 3600000)
  delta -= hours * 3600000
  const minutes = Math.floor(delta / 60000)
  delta -= minutes * 60000
  const seconds = Math.floor(delta / 1000)

  return [hours, minutes, seconds]
}

export function to2Digits(num) {
  const formatter = (n) =>
    n.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

  return formatter(num)
}
export function formatDuration(hour, min, sec) {
  console.log(hour, min, sec)
  const formatter = (n) =>
    n.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

  return [formatter(hour), formatter(min), formatter(sec)].join(':')
}
