import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
  ReactNode,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import Select from 'react-select'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  ButtonProps,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { SelectOption, YYYYMMDDDateString } from '../types/common'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateNonSongPracticeTaskFormData,
  CreateNonSongPracticeTaskFormDataSchema,
  PracticeTaskCategory,
} from './common'
import { PracticeSongOutputDto } from '../types/practiceSong'
import { GradientButton } from '../Components/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GRAY_LIGHT,
  GREEN,
  GREEN_800,
  GREEN_LIGHT,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { ErrorMessage } from '@hookform/error-message'

export interface AddNonSongTaskFormViewProps {
  date: YYYYMMDDDateString
  onSubmit: (data: CreateNonSongPracticeTaskFormData) => void
}

const categories = [
  {
    label: '스케일',
    value: PracticeTaskCategory.Enum.SONG,
  },
  {
    label: '즉흥연주',
    value: PracticeTaskCategory.Enum.IMPROVISATION,
  },
  {
    label: '코드',
    value: PracticeTaskCategory.Enum.CHORD,
  },
  {
    label: '리듬',
    value: PracticeTaskCategory.Enum.RHYTHM,
  },
  {
    label: '기타',
    value: PracticeTaskCategory.Enum.OTHERS,
  },
] as SelectOption<PracticeTaskCategory>[]

export default function AddNonSongTaskFormView({
  date,
  onSubmit,
}: AddNonSongTaskFormViewProps) {
  const { handleSubmit, control, register } =
    useForm<CreateNonSongPracticeTaskFormData>({
      resolver: zodResolver(CreateNonSongPracticeTaskFormDataSchema),
    })

  const _onSubmit = async (formData: CreateNonSongPracticeTaskFormData) => {
    const resp = await repoutil.post('practiceTasks', {
      ...formData,
      date: date,
      todo: {
        goalDuration: 0,
        isDone: false,
      },
    })
    const data = await resp.json()
    console.log(data)
    onSubmit(formData)
  }

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Box className="form-input-box" py={3} minHeight={'200px'}>
        <Controller
          control={control}
          name="practiceTaskCategory"
          render={({
            field: { name, onChange, value, ref },
            formState: { errors },
          }) => (
            <>
              <Select<SelectOption<PracticeTaskCategory>>
                placeholder="Select a category"
                ref={ref}
                value={categories.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                options={categories}
                menuPosition="fixed"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    // Generated by ChatGPT based on the primary color #34C61C
                    primary: '#34C61C', // Your primary green color
                    primary75: '#4FDA36', // Slightly lighter green (75% transparency)
                    primary50: '#8FF285', // Even lighter green (50% transparency)
                    primary25: '#BFF7BA', // Lightest green (25% transparency)
                    neutral0: '#ffffff', // Background color (white)
                    neutral5: '#f6f6f6', // Light grey for subtle elements
                    neutral10: '#e6e6e6', // Light grey for borders
                    neutral20: '#cccccc', // Border color
                    neutral30: '#b3b3b3', // Hover border color
                    neutral40: '#999999', // Disabled text color
                    neutral50: '#666666', // Default text color
                    neutral60: '#4d4d4d', // Active text color
                    neutral70: '#333333', // Hover text color
                    neutral80: '#1a1a1a', // Focused text color
                    neutral90: '#0d0d0d', // Darkest text color
                    danger: '#d9534f', // Danger color (red)
                    dangerLight: '#f2dede', // Light danger color
                  },
                })}
                styles={{
                  menuList: (base) => ({ ...base, maxHeight: '150px' }),
                }}
              />

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Typography variant={'caption'} color={'error'}>
                    {`${message}`}
                  </Typography>
                )}
              />
            </>
          )}
        />
        <Controller
          control={control}
          name="title"
          render={({ field, formState: { errors } }) => (
            <>
              <TextField
                id="titleInput"
                placeholder="Title"
                fullWidth={true}
                margin="dense"
                inputProps={{
                  style: { paddingTop: '8px', paddingBottom: '8px' },
                }}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name={field.name}
                render={({ message }) => (
                  <Typography variant={'caption'} color={'error'}>
                    {`${message}`}
                  </Typography>
                )}
              />
            </>
          )}
        />
        {/* <Typography>최근 추가한 연습</Typography> */}
      </Box>
      <GradientButton
        fullWidth
        type="submit"
        variant="contained"
        style={{
          fontSize: '16px',
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: 'bold',
        }}
        disableElevation={true}
      >
        Create
      </GradientButton>
    </form>
  )
}
