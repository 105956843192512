import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  tabsClasses,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { YYYYMMDDDateString } from '../types/common'
import { useForm } from 'react-hook-form'
import AddSongTaskFormView from './AddSongTaskFormView'
import AddNonSongTaskFormView from './AddNonSongTaskFormView'

export interface AddTaskViewProps {
  open: boolean
  date: YYYYMMDDDateString
  onClose: () => void
}

export type SongTabKind = {
  label: 'Song'
  value: 'SONG'
}

export type OthersTabKind = {
  label: 'Others'
  value: 'OTHERS'
}

export type TabKind = SongTabKind | OthersTabKind

const tabs = [
  { label: 'Song', value: 'SONG' },
  { label: 'Others', value: 'OTHERS' },
]

const defaultTab = { label: 'Song', value: 'SONG' } as SongTabKind

export default function AddTaskView({ open, date, onClose }: AddTaskViewProps) {
  const [selectedTab, setSelectedTab] = useState<TabKind>(defaultTab)

  const onChangeTab = (e: SyntheticEvent, newValue: string) => {
    console.log('ere')
    switch (newValue) {
      case 'SONG':
        setSelectedTab({ label: 'Song', value: 'SONG' } as SongTabKind)
        break
      case 'OTHERS':
        setSelectedTab({ label: 'Others', value: 'OTHERS' } as OthersTabKind)
        break
      default:
        console.error('Invalid tab value', newValue)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          mx: 0,
          maxWidth: '640px',
          top: '-15%',
          width: 'calc(100% - 32px)',
          p: '16px',
        },
      }}
    >
      <Tabs
        value={selectedTab.value}
        onChange={onChangeTab}
        variant="fullWidth"
      >
        {tabs.map((tab) => (
          <Tab
            value={tab.value}
            label={tab.label}
            sx={{ textTransform: 'none' }}
          />
        ))}
      </Tabs>
      {selectedTab.value === 'SONG' && (
        <AddSongTaskFormView
          date={date}
          onSubmit={(e) => {
            onClose()
          }}
        />
      )}
      {selectedTab.value === 'OTHERS' && (
        <AddNonSongTaskFormView
          date={date}
          onSubmit={(e) => {
            onClose()
          }}
        />
      )}
    </Dialog>
  )
}
