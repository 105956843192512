import NotificationList from "./NotificationList";
import PlayingLogList from "./PlayingLogList";
import Box from '@mui/material/Box';

let notifications = [{"kind": "notification", "body": "기타 연습을 안 한지 N일 째에요.\n오늘은 멋지게 기타 연습을 해볼까요?"}]
let histories = [
    {
        "createdAt": new Date().toISOString().
            replace(/T.*$/, ''),
        "num": 3,
        "category": ["Pentatonic Scale"],
        "content": "A minor 1번폼부터 5번폼까지 2번 왕복함. 이제 1번폼~3번폼까지는 자연스럽게 흐르면서 가능한듯",
    },{
        "createdAt": new Date().toISOString().
            replace(/T.*$/, ''),
        "category": ["Blues"],
        "num": 5,
        "song": "Blues in G",
        "artist": "B.B. King",
        "content": "주말이라 좀 열심히 했다. 처음엔 좀 막막했는데 연습하니까 되긴하네",
    },

]


export default function Home() {
    return (
        <Box>
            <NotificationList notifications={notifications} />
            <PlayingLogList histories={histories}/>
        </Box>
    );
  }