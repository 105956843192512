import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import * as dateutil from '../util/dateutil'
import * as repoutil from '../util/repoutil'
import { PracticeSongOutputDto } from '../types/practiceSong'

export const retrievePracticeSongs = async (
  songKind = 'RELEASED',
): Promise<PracticeSongOutputDto[]> => {
  const resp = await repoutil.http(`practiceSongs?songKind=${songKind}`)
  const data = await resp.json()
  return data.data as PracticeSongOutputDto[]
}

export const practiceSongsState = atom({
  key: 'practiceSongs',
  default: await retrievePracticeSongs(),
})
