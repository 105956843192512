import { SongKind } from '../types/practiceSong'
import { z } from 'zod'

export const PracticeTaskCategory = z.enum([
  'SONG',
  'SCALE',
  'IMPROVISATION',
  'CHORD',
  'RHYTHM',
  'OTHERS',
])

export type PracticeTaskCategory = z.infer<typeof PracticeTaskCategory>

export type TodoPracticeAchievementType = 'REPEAT' | 'TODO'

export const CreateSongPracticeTaskFormDataSchema = z.object({
  title: z.string({ message: 'Please enter a title' }),
  practiceSongId: z.number({
    message: 'Please select a song',
  }),
})

export type CreateSongPracticeTaskFormData = z.infer<
  typeof CreateSongPracticeTaskFormDataSchema
>

export const CreateNonSongPracticeTaskFormDataSchema = z.object({
  title: z.string({ message: 'Please enter a title' }),
  practiceTaskCategory: PracticeTaskCategory,
})

export type CreateNonSongPracticeTaskFormData = z.infer<
  typeof CreateNonSongPracticeTaskFormDataSchema
>
