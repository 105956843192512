import * as repoutil from '../util/repoutil'
export const getSongPageBaseUrl = () => '/songs'
export const getSongListPageUrl = () => `${getSongPageBaseUrl()}/list`
export const getCreateSongPageUrl = () => `${getSongPageBaseUrl()}/create`
export const getUpdateSongPageUrl = () => `${getSongPageBaseUrl()}/update`

export const getCalendarPageBaseUrl = () => '/calendar'
export const getMyCalendarPageUrl = () =>
  `${getCalendarPageBaseUrl()}/${repoutil.getUserInfo()['username']}`
export const getSignInUrl = () => `/signin`
export const getSignUpUrl = () => `/signup`
export const getHomeUrl = () => `/home`
export const getRedirectUrlAfterAuthentication = () => {
  if (process.env.NODE_ENV === 'development') {
    return getSignInUrl()
  }
  return getHomeUrl()
}
