import React, { useState, useEffect, Fragment, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Search,
  Home,
  MusicNote,
  LibraryMusic,
  Settings,
  CalendarMonthOutlined,
} from '@mui/icons-material'
import CustomToggleButtonGroup from './CustomToggleButtonGroup'
import PlayingJournalView from './PlayingJournalView/PlayingJournalView'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'

import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import {
  getCalendarPageBaseUrl,
  getHomeUrl,
  getMyCalendarPageUrl,
  getSongListPageUrl,
  getSongPageBaseUrl,
} from './config/config'

function CustomBottomNavigation(_, ref) {
  const pathname = useLocation().pathname

  const navigate = useNavigate()

  const getNormalizedPath = (rawPath) => {
    if (rawPath.startsWith(getSongPageBaseUrl())) {
      return getSongPageBaseUrl()
    }
    return rawPath
  }
  const [path, setPath] = React.useState(getNormalizedPath(pathname))
  const handleChange = (event, newPath) => {
    const newNormalizedPath = getNormalizedPath(newPath)

    setPath(newNormalizedPath)
    switch (newNormalizedPath) {
      case getHomeUrl():
        navigate(getHomeUrl(), { replace: true })
        break
      case getMyCalendarPageUrl():
        navigate(getMyCalendarPageUrl(), { replace: true })
        break
      case '/search':
        navigate('/search', { replace: true })
        break
      case '/settings':
        navigate('/settings', { replace: true })
        break
      case getSongPageBaseUrl():
        return navigate(getSongListPageUrl(), { replace: true })
    }
  }

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }}
      elevation={3}
      ref={ref}
    >
      <BottomNavigation
        sx={{ width: '100%', paddingTop: '4px', marginBottom: '8px' }}
        value={path}
        onChange={handleChange}
      >
        <BottomNavigationAction
          comnponent={Link}
          value={getHomeUrl()}
          label="Home"
          icon={<Home />}
        />
        <BottomNavigationAction
          comnponent={Link}
          value={getMyCalendarPageUrl()}
          label="Calendar"
          icon={<CalendarMonthOutlined />}
        />
        {/* <BottomNavigationAction
          label="Search"
          value="search"
          to='/search'
          icon={<Search />}
          comnponent={Link}
        /> */}
        <BottomNavigationAction
          comnponent={Link}
          value={getSongPageBaseUrl()}
          label="Songs"
          icon={<LibraryMusic />}
        />
        <BottomNavigationAction
          comnponent={Link}
          value="/settings"
          label="Settings"
          icon={<Settings />}
        />
      </BottomNavigation>
    </Paper>
  )
}

export default forwardRef(CustomBottomNavigation)
