import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import * as dateutil from '../util/dateutil'
import * as repoutil from '../util/repoutil'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { YYYYMMDDDateString } from '../types/common'

export const retrievePracticeDay = async (
  dateString: YYYYMMDDDateString,
): Promise<PracticeDayDetailedOutputDto> => {
  const resp = await repoutil.http(`practiceDays/${dateString}`)
  const data = await resp.json()
  return data.data as PracticeDayDetailedOutputDto
}

export const currentPracticeDayState = atom({
  key: 'currentPracticeDay',
  default: await retrievePracticeDay(dateutil.formatDate(new Date())),
})

export const todayPracticeDayState = atom({
  key: 'todayPracticeDay',
  default: await retrievePracticeDay(dateutil.formatDate(new Date())),
})
