import logo from './logo.svg'
import React, { Fragment, useState, useEffect } from 'react'
import './App.css'
import { Box, Stack, Divider } from '@mui/material'

import { buttonClasses } from '@mui/material/Button'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from 'react-router-dom'
import Home from './Home/Home'
import CalendarView from './CalendarView'
import AppTitleBar from './AppTitleBar'
import FeedPage from './CalendarPage'
import * as dateutil from './util/dateutil'
import WritePlayingJournal from './WritePlayingJournal/WritePlayingJournal'
import * as repoutil from './util/repoutil'
import SignInPage from './OnBoarding/SignInPage'
import SignUpPage from './OnBoarding/SignUpPage'
import SettingsPage from './Settings/SettingsPage'
import AuthenticatedRoute from './AuthenticatedRoute'
import { createTheme } from '@mui/material/styles'
import { styled, ThemeProvider } from '@mui/material/styles'
import UpdateProfilePage from './UpdateProfilePage'
import AppleSignInCallback from './OnBoarding/AppleSignInCallback'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import { theme } from './theme/theme'
import PracticeSongListPage from './PracticeSong/PracticeSongListPage'
import {
  getSongListPageUrl,
  getCreateSongPageUrl,
  getUpdateSongPageUrl,
  getRedirectUrlAfterAuthentication,
  getHomeUrl,
  getCalendarPageBaseUrl,
} from './config/config'
import WritePracticeSongPage from './PracticeSong/WritePracticeSongPage'
import PracticePage from './Practice/PracticePage'
import ImportPracticePage from './Practice/ImportPracticePage'
import HomePage from './HomePage'
import WritePostPage from './WritePostPage'
import EditPostPage from './EditPostPage'

function App() {
  const [playingJournalToUpdate, setPlayingJournalToUpdate] = useState(null)
  const getWritePlayingJournalInput = (playingJournal) => {
    if (playingJournal == null) return null
    return {
      title: playingJournal.title,
      description: playingJournal.description,
      tasks: playingJournal.tasks.map((task) => {
        return {
          id: task.id,
          title: task.title,
          duration: task.duration,
          doneAt: task.doneAt,
          isDone: task.isDone,
        }
      }),
    }
  }

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <AuthenticatedRoute
                  element={
                    <Navigate
                      to={{
                        pathname: getRedirectUrlAfterAuthentication(),
                      }}
                      replace={true}
                    />
                  }
                />
              }
            />
            <Route
              exact
              path={getHomeUrl()}
              element={<AuthenticatedRoute element={<HomePage />} />}
            />
            <Route
              exact
              path="/practice/import"
              element={<AuthenticatedRoute element={<ImportPracticePage />} />}
            />
            <Route exact path="/signin" element={<SignInPage />} />
            <Route exact path="/signup" element={<SignUpPage />} />
            <Route exact path="/settings" element={<SettingsPage />} />
            <Route
              exact
              path="/signin/apple/callback"
              element={<AppleSignInCallback />}
            />
            <Route
              exact
              path="/playingJournals/write"
              element={<AuthenticatedRoute element={<WritePlayingJournal />} />}
            />
            <Route
              exact
              path="/profile/update"
              element={<AuthenticatedRoute element={<UpdateProfilePage />} />}
            />
            <Route
              exact
              path="/playingJournals/edit/:playingJournalId"
              element={
                <AuthenticatedRoute
                  element={
                    <WritePlayingJournal
                      isCreate={false}
                      writePlayingJournalInputProps={getWritePlayingJournalInput(
                        playingJournalToUpdate,
                      )}
                      mediaSourcesProps={
                        playingJournalToUpdate != null
                          ? playingJournalToUpdate['mediaList']
                          : []
                      }
                    />
                  }
                />
              }
            />
            <Route
              exact
              path={`/${getCalendarPageBaseUrl()}/:feedUsername`}
              element={
                <FeedPage
                  setPlayingJournalToUpdate={setPlayingJournalToUpdate}
                />
              }
            />
            <Route exact path="/practice" element={<PracticePage />} />
            <Route exact path="/posts/write" element={<WritePostPage />} />
            <Route exact path="/posts/edit" element={<EditPostPage />} />
            <Route
              exact
              path={getSongListPageUrl()}
              element={<PracticeSongListPage />}
            />
            <Route
              exact
              path={getCreateSongPageUrl()}
              element={<WritePracticeSongPage />}
            />
            <Route
              exact
              path={getUpdateSongPageUrl()}
              element={<WritePracticeSongPage />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
