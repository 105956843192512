import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  ChangeEvent,
  SyntheticEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { format, subDays } from 'date-fns'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  Chip,
  Modal,
  TextField,
  SwipeableDrawer,
  Alert,
  Input,
  buttonClasses,
  ButtonProps,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Visibility,
  VisibilityOff,
  AccessTime,
  PlayArrow,
  Pause,
} from '@mui/icons-material'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import AddTaskView from '../PracticeDayView/AddTaskView'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as timeutil from '../util/timeutil'
import * as expressionutil from '../util/expressionutil'
import {
  retrievePracticeDay,
  currentPracticeDayState,
} from '../state/practiceday'
import CongratsLottie1 from '../PracticeDayView/congratsLottie1.json'
import CongratsLottie2 from '../PracticeDayView/congratsLottie2.json'
import { useLottie } from 'lottie-react'
import {
  GRAY_DARK,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_LIGHTEST,
} from '../theme/theme'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import PracticeSongView from './PracticeSongView'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { GradientButton } from '../Components/Button'
import { PracticeTaskCategory } from '../PracticeDayView/common'
import NonSongPracticeTaskGroupHeader from './NonSongPracticeTaskGroupHeader'
import { z } from 'zod'
import { PracticeNoteOutputDto } from '../types/practiceNote'
import PracticeTaskGroupListView from './PracticeTaskGroupListView'
import styles from './PracticePage.module.css'

const style = {
  position: 'absolute',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  bgcolor: GRAY_LIGHTEST,
  borderRadius: '10px',
  minHeight: '100vh',
  maxHeight: '100vh',
}

interface PracticePageProps {}

const TimeUnit = z.enum(['HOUR', 'MINUTE', 'SECOND'])
type TimeUnit = z.infer<typeof TimeUnit>

interface Timer {
  isRunning: boolean
  startedAt: Date | null
  elapsedDurationMillisecond: number
  deltaDurationMillisecond: number
  [TimeUnit.Enum.HOUR]: number
  [TimeUnit.Enum.MINUTE]: number
  [TimeUnit.Enum.SECOND]: number
}

const buildHourMinuteSecond = (durationMillisecond: number) => {
  const hour = Math.floor(durationMillisecond / 1000 / 60 / 60)
  const minute = Math.floor(
    (durationMillisecond - hour * 60 * 60 * 1000) / 1000 / 60,
  )
  const second = Math.floor(
    (durationMillisecond - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000,
  )
  return {
    [TimeUnit.Enum.HOUR]: hour,
    [TimeUnit.Enum.MINUTE]: minute,
    [TimeUnit.Enum.SECOND]: second,
  }
}

// 특정 일자의 연습을 수정하는 모달창
// 오늘의 연습 시작 화면 등도 결국 특정 일자의 연습을 수정하는 것이기에 이 모달창에서 진행될 수 있다.
export default function PracticePage({}: PracticePageProps) {
  const navigate = useNavigate()
  const [showRoutines, setShowRoutines] = useState(false)
  const [currentPracticeDay, setCurrentPracticeDay] =
    useRecoilState<PracticeDayDetailedOutputDto>(currentPracticeDayState)
  const [pinnedPracticeNotes, setPinnedPracticeNotes] = useState<
    PracticeNoteOutputDto[]
  >([])
  const [timerInputType, setTimerInputType] = useState<'text' | 'number'>(
    'number',
  )
  const [timer, setTimer] = useState<Timer>({
    isRunning: false,
    startedAt: null, // new Date()
    elapsedDurationMillisecond: 0,
    deltaDurationMillisecond: 0,
    [TimeUnit.Enum.HOUR]: 0,
    [TimeUnit.Enum.MINUTE]: 0,
    [TimeUnit.Enum.SECOND]: 0,
  })
  console.log(timer)
  const tickerRef = useRef<NodeJS.Timeout | undefined>()

  const loadPracticeDay = async () => {
    setCurrentPracticeDay(await retrievePracticeDay(currentPracticeDay.date))
  }

  const [openAddTaskView, setOpenAddTaskView] = useState<boolean>(false)

  const onClickAddTask = () => {
    setOpenAddTaskView(true)
  }

  const onCloseAddTask = () => {
    setOpenAddTaskView(false)
    // 혹시 AddTask 화면에서 연습을 추가했을 경우를 대비하여 다시 fetch
    loadPracticeDay()
  }

  const importPracticeButtonRef = useRef<HTMLInputElement>(null)
  const onClickImportPractice = async (e: SyntheticEvent) => {
    if (importPracticeButtonRef.current) {
      importPracticeButtonRef.current.disabled = true
    }

    navigate(`/practice/import?date=${currentPracticeDay.date}`)

    if (importPracticeButtonRef.current) {
      importPracticeButtonRef.current.disabled = false
    }
  }

  const onClickDeleteTask = async (taskId: number) => {
    const resp = await repoutil.del(`practiceTasks/${taskId}`)

    // reload
    await loadPracticeDay()
  }

  const onClickTimerPlay = () => {
    if (timer.isRunning) return

    setTimer((prev) => {
      return {
        isRunning: true,
        startedAt: new Date(),
        elapsedDurationMillisecond: prev.elapsedDurationMillisecond,
        deltaDurationMillisecond: 0,
        ...buildHourMinuteSecond(prev.elapsedDurationMillisecond),
      } as Timer
    })
    tickerRef.current = setInterval(() => {
      setTimer((prev: Timer) => {
        if (!prev.isRunning) return prev

        const newDeltaDurationMillisecond = prev?.startedAt
          ? new Date().getTime() - prev.startedAt.getTime()
          : 0
        console.log(newDeltaDurationMillisecond)
        return {
          ...prev,
          deltaDurationMillisecond: newDeltaDurationMillisecond,
          ...buildHourMinuteSecond(
            prev.elapsedDurationMillisecond + newDeltaDurationMillisecond,
          ),
        }
      })
    }, 1000)
  }

  const finishTimer = async () => {
    clearInterval(tickerRef.current)
    const newElapsedDurationMillisecond =
      timer.elapsedDurationMillisecond + timer.deltaDurationMillisecond
    setTimer({
      ...timer,
      isRunning: false,
      startedAt: null,
      elapsedDurationMillisecond: newElapsedDurationMillisecond,
      deltaDurationMillisecond: 0,
      ...buildHourMinuteSecond(newElapsedDurationMillisecond),
    })

    const resp = await repoutil.post(
      `practiceDays/${currentPracticeDay.date}/updateStatus`,
      {
        practiceDuration: `PT${newElapsedDurationMillisecond / 1000}S`,
      },
    )
    const data = await resp.json()
  }

  const onClickTimerPause = async () => {
    await finishTimer()
  }

  const refreshPinnedPracticeNotes = async () => {
    const resp = await repoutil.http(`practiceNotes?isPinned=true`)
    const data = await resp.json()
    setPinnedPracticeNotes(data.data as PracticeNoteOutputDto[])
  }

  const refreshPracticeDay = async () => {
    const resp = await repoutil.http(`practiceDays/${currentPracticeDay.date}`)
    const data = await resp.json()
    await setCurrentPracticeDay(data.data)
  }

  const handleSavePractice = async () => {
    await finishTimer()
    refreshPracticeDay()

    navigate(-1)
  }

  const toggleShowRoutines = () => {
    setShowRoutines(!showRoutines)
  }

  const { View, play, stop, setSpeed } = useLottie({
    loop: false,
    autoplay: false,
    animationData: CongratsLottie1,
    style: {
      position: 'absolute',
      pointerEvents: 'none',
      width: '100%',
      zIndex: 1000,
    },
  })

  const onTaskDone = (e: Event, task: any) => {
    stop()
    setSpeed(1.5)
    play()
  }

  useEffect(() => {
    refreshPinnedPracticeNotes()
    setTimer({
      ...timer,
      elapsedDurationMillisecond: currentPracticeDay.practiceDuration * 1000,
      ...buildHourMinuteSecond(currentPracticeDay.practiceDuration * 1000),
    })
  }, [currentPracticeDay])

  const hourRef = useRef<HTMLInputElement>(null)
  const minuteRef = useRef<HTMLInputElement>(null)
  const secondRef = useRef<HTMLInputElement>(null)

  const generateOnFocus =
    (selfRef: React.RefObject<HTMLInputElement>) =>
    (event: React.FocusEvent) => {
      finishTimer()
      selfRef.current?.setSelectionRange(
        selfRef.current.value.length,
        selfRef.current.value.length,
      )
      setTimerInputType('number')
    }
  const generateOnBlur =
    (selfRef: React.RefObject<HTMLInputElement>) =>
    (event: React.FocusEvent) => {
      setTimerInputType('text')
    }
  const generateOnKeyDown =
    (nextRef?: React.RefObject<HTMLInputElement>) =>
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        nextRef?.current?.focus()
      }
    }

  const handleChange =
    (unit: TimeUnit) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value)
      if (value < 0 || value > 99 || isNaN(value)) return

      const newTimer = { ...timer, [unit]: value } as Timer
      newTimer.elapsedDurationMillisecond =
        (newTimer[TimeUnit.Enum.HOUR] * 60 * 60 +
          newTimer[TimeUnit.Enum.MINUTE] * 60 +
          newTimer[TimeUnit.Enum.SECOND]) *
        1000

      setTimer({
        ...newTimer,
      })
    }
  const timeUnits = [
    {
      unit: TimeUnit.Enum.HOUR,
      ref: useRef<HTMLInputElement | null>(null),
    },
    {
      unit: TimeUnit.Enum.MINUTE,
      ref: useRef<HTMLInputElement | null>(null),
    },
    {
      unit: TimeUnit.Enum.SECOND,
      ref: useRef<HTMLInputElement | null>(null),
    },
  ]

  return (
    <>
      {View}
      <Grid
        className={styles.scrollbarHidden}
        container
        justifyContent={'center'}
        sx={style}
        py={2}
        px={2}
      >
        <Grid item xs={12} marginBottom={1}>
          <Typography fontWeight={'bold'}>
            {dateutil.formatDateToHumanString(
              new Date(currentPracticeDay.date),
            )}{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Grid
                container
                direction={'row'}
                justifyContent={'center'}
                rowSpacing={2}
              >
                <Grid item xs={12}>
                  {(() => {
                    const resourceColor = '#FFFFFF'
                    return (
                      <Stack
                        direction={'row'}
                        style={{
                          background:
                            'linear-gradient(315deg, #ABD453 0%, #91D73E 30%, #91D73E 70%, #46E460 100%)',
                          borderRadius: '10px',
                          fontSize: '16px',
                        }}
                        alignItems={'center'}
                        padding={1}
                        spacing={'auto'}
                      >
                        <AccessTime style={{ color: resourceColor }} />
                        <Stack
                          style={{}}
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                          spacing={1}
                        >
                          {timeUnits.map(({ unit, ref }, i) => (
                            <Fragment key={unit}>
                              <input
                                type={timerInputType}
                                ref={ref}
                                onKeyDown={generateOnKeyDown(
                                  timeUnits[i + 1]?.ref,
                                )}
                                // TODO: 제대로 동작 안하네...
                                // onFocus={generateOnFocus(ref)}
                                // onBlur={generateOnBlur(ref)}
                                onChange={handleChange(unit)}
                                className="border-none focus:outline-none font-bold text-white bg-transparent"
                                // TODO: 이것은 매우 불안한 임시 값이다... 2자리 숫자의 경우 working하는 중
                                style={{
                                  width: '30px',
                                  fontSize: '20px',
                                }}
                                value={timeutil.to2Digits(timer[unit])}
                              />
                              {i < timeUnits.length - 1 && (
                                <span
                                  className="border-none focus:outline-none font-bold text-white bg-transparent"
                                  style={{ fontSize: '20px' }}
                                >
                                  :
                                </span>
                              )}
                            </Fragment>
                          ))}
                        </Stack>
                        {timer.isRunning ? (
                          <Pause
                            style={{ color: resourceColor }}
                            onClick={onClickTimerPause}
                          />
                        ) : (
                          <PlayArrow
                            style={{ color: resourceColor }}
                            onClick={onClickTimerPlay}
                          />
                        )}
                      </Stack>
                    )
                  })()}
                </Grid>
                <Grid item xs={12}>
                  {pinnedPracticeNotes.length > 0 && (
                    <Typography color={GRAY_LIGHT} fontSize={'14px'} mb={1}>
                      Pinned Posts
                    </Typography>
                  )}
                  <Stack spacing={1}>
                    {pinnedPracticeNotes.map((practiceNote, i) => (
                      <Typography
                        p={1.5}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          '& fieldset': { border: 'none' },
                          whiteSpace: 'pre-wrap',
                        }}
                        fontSize={'16px'}
                        color={GRAY_DARK}
                      >
                        {practiceNote.content}
                      </Typography>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <PracticeTaskGroupListView
                practiceTaskGroups={currentPracticeDay.practiceTaskGroups}
                readonly={false}
                onClickDeleteTask={onClickDeleteTask}
                onTaskDone={onTaskDone}
              />
            </Grid>

            <Grid item xs={5.8} marginTop={3}>
              <OutlinedButton
                fullWidth
                variant="outlined"
                onClick={onClickAddTask}
                style={{
                  backgroundColor: 'white',
                  fontSize: '16px',
                  textTransform: 'none',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                }}
              >
                Add Practice
              </OutlinedButton>
              <AddTaskView
                date={currentPracticeDay.date}
                open={openAddTaskView}
                onClose={onCloseAddTask}
              />
            </Grid>

            <Grid item xs={5.8} marginTop={3}>
              <GradientButton
                fullWidth
                variant="contained"
                onClick={onClickImportPractice}
                disableElevation={true}
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                }}
              >
                Import Practice
              </GradientButton>
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <GradientButton
                fullWidth
                variant="contained"
                onClick={handleSavePractice}
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                }}
                disableElevation={true}
              >
                Finish
              </GradientButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const OutlinedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'white',
  color: GREEN,
  textTransform: 'none',
  transition: theme.transitions.create(['background', 'transform'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: 'white',
    transform: 'scale(1.05)',
  },
  '&:active': {
    backgroundColor: 'white',
    transform: 'scale(0.95)',
  },
  '&:disabled': {
    backgroundColor: 'white',
    color: theme.palette.text.disabled,
  },
}))
